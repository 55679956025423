
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'

import {
    setNextPreviewsInfo,
} from "../redux/actions/dataActions";

import { loadTrack } from './audioHelper';
import { eventLog } from './eventHelper';

// Algolia.
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from '../constants/algolia_constants';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';
import {
    useHits,
    useSearchBox,
} from 'react-instantsearch-core';

import {
    Configure,
    Highlight,
    Index,
    InstantSearch
} from 'react-instantsearch';


// Components.
import EntityInfoDrawer from './EntityInfoDrawer';
import SpeakerHeader from './SpeakerHeader';
import SpeakerTrack from './SpeakerTrack';

import { PlayArrowRounded, SearchRounded } from '@material-ui/icons';

import Highlighter from 'react-highlight-words';
import useWindowDimensions from './windowHelper';
import BottomHomeMenu from './BottomHomeMenu';
import { fancyTimeFormatMinutesOrSeconds, getDateString } from './timeHelper';
import BookmarkButton from './BookmarkButton';
import PreviewShareButton from './PreviewShareButton';


const ResultsLabel = ({ query, hits }) => {
    return (
        <div style={{ padding: '20px 20px 10px' }}>
            {
                hits.length === 0 ?
                    <div className='heading'>
                        No previews found for "{query}"
                    </div>
                    :
                    <div className='subheading'>
                        Episodes and Previews
                    </div>
            }
        </div>
    )
}

const AlgoliaSearch = ({
    initialQuery,
    noQueryView,

    // Redux.
    authenticated,
    setNextPreviewsInfo,
}) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    useEffect(() => {
        if (!!initialQuery) {
            eventLog(13, {
                'topicId': initialQuery,
            });
        }
    }, []);

    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    const playSearchResult = (query, hit, hits) => {
        loadTrack(hit.mp3URL, 0);
        setNextPreviewsInfo([hit])
        eventLog(14, {
            'previewId': hit.id,
            'speakerId': hit.person.id,
            'topicIds': hit.topics,
            'query': query,
        })
        navigate('/previews', { state: { closeAllModal: true } });
    };

    const playTrack = (trackId) => () => {
        navigate('/track/' + trackId);
    }

    const SearchTopicSuggestions = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <TopicSuggestionHits query={query} refine={refine} width={props.width} />
            </Index>
        );
    }

    const MaybeShowPerson = ({ query, hits, authenticated }) => {

        const [person, setPerson] = useState(null);
        const [speakerTracks, setSpeakerTracks] = useState([]);

        const [openPersonInfo, setOpenPersonInfo] = useState(false);

        const onSpeakerClick = () => {
            setOpenPersonInfo(true);
        };

        const togglePersonInfo = (open) => (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            if (!!person && !!person.id) {
                setOpenPersonInfo(open);
            }
        }

        useEffect(() => {
            setPerson(null);
            if (hits && query) {
                for (let i = 0; i < hits.length; i++) {
                    const hitPerson = hits[i].speaker;
                    if (query.length * 4 >= hitPerson.name.length) {
                        if (hitPerson.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
                            setPerson(hitPerson);
                            setSpeakerTracks(hits[i].tracks);
                        }
                        break;
                    }
                }
            }
        }, [query, hits]);

        return (
            !person ? <div className='thinRightDivider'></div> :
                <div style={{ padding: '16px 0px' }}>
                    <EntityInfoDrawer
                        open={openPersonInfo}
                        onClose={togglePersonInfo(false)}
                        speakerId={person.id} />
                    <SpeakerHeader summary={null} speaker={person} onSpeakerClick={onSpeakerClick} />

                    <div>
                        <div className='subheading' style={{ padding: '20px 20px 0px' }}>
                            Trending episodes with {person.name}
                        </div>
                        {
                            speakerTracks.map((track, index) => (
                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(224, 224, 224)' }}>
                                    <SpeakerTrack speakerId={person.id} track={track} authenticated={authenticated} />
                                </div>
                            ))
                        }
                    </div>

                </div>
        )
    };

    const PreviewHits = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        const { hits, results, sendEvent } = useHits(props);
        const {
            playSearchResult,
        } = props;

        useEffect(() => {
            document.getElementById('homeContent').scrollTo(0, 0)
            document.getElementById('audio').pause();
        }, [query]);

        const groupHitsByTrack = () => {
            let trackIdOrder = [];
            let trackIdToHits = {};
            for (let i = 0; i < hits.length; i++) {
                const hit = hits[i];
                const trackId = hit.fullTrackId;
                if (!(trackId in trackIdToHits)) {
                    trackIdOrder.push(trackId);
                    trackIdToHits[trackId] = [hit];
                } else {
                    trackIdToHits[trackId].push(hit);
                }
            }
            let retval = [];
            for (let i = 0; i < trackIdOrder.length; i++) {
                const trackId = trackIdOrder[i];
                retval.push({
                    'id': trackId,
                    'hits': trackIdToHits[trackId]
                })
            }
            return retval;
        }

        const Hit = ({ hit }) => {
            return (
                <div>
                    <table style={{ textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td style={{ minWidth: '66px', verticalAlign: 'top' }}>
                                    <button onClick={() => {
                                        playSearchResult(query, hit, hits)
                                    }}>
                                        <img alt='profile' src={hit.person.imageURL} style={{ width: '55px', height: '55px', border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                                    </button>
                                </td>
                                <td style={{ width: '100%', verticalAlign: 'top' }}>
                                    <div>
                                        <button className='searchResultTitle'
                                            onClick={() => {
                                                playSearchResult(query, hit, hits)
                                            }}>
                                            <Highlight hit={hit} attribute="title" />
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={() => {
                                            playSearchResult(query, hit, hits)
                                        }} style={{ margin: '8px 0px', textAlign: 'left' }}>
                                            <div className='speakerDescription' style={{ fontWeight: '500' }}>
                                                <Highlight hit={hit} attribute="person.name" />
                                            </div>
                                            <div className='speakerDescription'>
                                                <Highlight hit={hit} attribute="person.description" />
                                            </div>
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={() => {
                                            playSearchResult(query, hit, hits)
                                        }}>
                                            <div style={{ marginTop: '4px', display: 'inline-block', background: 'rgb(245, 245, 245)', padding: '8px 20px 8px 12px', fontSize: '15px', fontWeight: '500', borderRadius: '20px' }}>
                                                <PlayArrowRounded style={{ verticalAlign: 'middle', fontSize: '24px', marginRight: '5px' }} />
                                                <span style={{ verticalAlign: 'middle' }}>
                                                    {fancyTimeFormatMinutesOrSeconds(hit.totalTimeSeconds)}
                                                </span>
                                            </div>
                                        </button>

                                        <PreviewShareButton
                                            buttonStyle={{ verticalAlign: 'middle', marginLeft: '20px' }}
                                            iconStyle={{ fontSize: '20px', verticalAlign: 'middle' }}
                                            trackId={hit.fullTrackId}
                                            messageIndex={hit.messageIndex}
                                            title={hit.title}
                                            showLabel={false}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className='searchResults'>
                <ResultsLabel query={query} hits={hits} />

                <div style={{ padding: '16px 0px' }}>
                    {groupHitsByTrack().map((hitsByTrack, trackIndex) => (
                        <div key={trackIndex} style={{ paddingTop: trackIndex === 0 ? '0px' : '20px' }}>
                            <table style={{ width: '100%', textAlign: 'left', padding: '0px 20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ minWidth: '76px' }}>
                                            <button onClick={navigateToUrl('/podcast/' + hitsByTrack.hits[0].source.id)}>
                                                <img src={hitsByTrack.hits[0].source.imageURL} className='trackInfoSourceImage' />
                                            </button>
                                        </td>
                                        <td style={{ width: '100%' }}>
                                            <button
                                                onClick={playTrack(hitsByTrack.hits[0].fullTrackId)}
                                                style={{ textAlign: 'left', width: '100%' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <div className='trackInfoSourceTitle'>
                                                        {hitsByTrack.hits[0].source.title}
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'rgb(80, 80, 80)', fontSize: '13px', verticalAlign: 'middle' }}>
                                                            {getDateString(hitsByTrack.hits[0].publishedDate)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </td>
                                        <td>
                                            <BookmarkButton trackId={hitsByTrack.id}
                                                buttonClassName=''
                                                iconStyle={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '25px',
                                                }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ margin: '16px 20px' }}>
                                {hitsByTrack.hits.map((hit, hitIndex) => (
                                    <div key={hit.id} style={{ borderLeft: '1px solid rgb(224, 224, 224)', paddingLeft: '10px', paddingTop: hitIndex === 0 ? '0px' : '16px' }}>
                                        <Hit hit={hit} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        )
    }

    const TopicSuggestionHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        const isExactMatch = (hit) => {
            return hit.name === props.query.toLowerCase();
        }

        const SuggestionHit = ({ hit, isLast }) => {
            const searchHit = () => {
                eventLog(13, {
                    'topicId': hit.name,
                });
                props.refine(hit.name);
                const inputElem = document.getElementsByClassName('aa-Input')[0];
                if (!!inputElem) {
                    inputElem.value = hit.name;
                } else {
                    const inputElemMobile = document.getElementsByClassName('aa-DetachedSearchButtonQuery')[0];
                    if (!!inputElemMobile) {
                        inputElemMobile.innerText = hit.name;
                    }
                }
            }

            return (
                isExactMatch(hit) ?
                    <div></div>
                    :
                    <table style={{ padding: '10px 16px 10px 14px', marginLeft: '20px', borderBottom: isLast ? '' : '0.5px solid rgb(224, 224, 224)' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <button onClick={searchHit}>
                                        <SearchRounded style={{ color: 'rgb(120, 120, 120)', marginRight: '6px', fontSize: '21px', verticalAlign: 'middle' }} />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ textAlign: 'left', width: '100%' }} onClick={searchHit}>
                                        <div style={{ fontSize: '16px' }}>
                                            <Highlighter
                                                highlightClassName="ais-Highlight-highlighted"
                                                unhighlightClassName="ais-Highlight-nonHighlighted"
                                                searchWords={[props.query]}
                                                autoEscape={true}
                                                textToHighlight={hit.name}
                                            />
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            )
        }

        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <div className='searchResults'>
                    {hits.map((hit, i) => (
                        <SuggestionHit key={i} hit={hit} isLast={i === hits.length - 1} />
                    ))}
                </div>
            </Index>
        );
    }

    const SearchSpeakers = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <SpeakerHits query={query} authenticated={props.authenticated} />
            </Index>
        );
    }

    const SpeakerHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <MaybeShowPerson query={props.query} hits={hits} authenticated={props.authenticated} />
            </Index>
        )
    }

    return (
        <div className='mainWithBottomMenu'>
            {!initialQuery ? noQueryView :
                <InstantSearch
                    indexName="previews"
                    initialUiState={{
                        "previews": {
                            query: initialQuery ?? '',
                        }
                    }}
                    searchClient={searchClient}>
                    <div style={{ padding: '60px 0px 20px 0px' }}>
                        <SearchTopicSuggestions navigateToUrl={navigateToUrl} width={width} />
                        <SearchSpeakers authenticated={authenticated} />
                        <Configure hitsPerPage={20} />
                        <PreviewHits
                            playSearchResult={playSearchResult}
                        />
                    </div>
                </InstantSearch>
            }
            <BottomHomeMenu />
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
    setNextPreviewsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(AlgoliaSearch);