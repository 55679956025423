import dayjs from 'dayjs';

export const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export const fancyTimeFormatMinutesOrSeconds = (duration) => {
    // if duration is less than 60, return string in seconds.
    if (duration < 60) {
        return ~~duration + "s";
    }

    // else return string in minutes without seconds.
    var mins = ~~((duration % 3600) / 60);
    return mins + "m";
}


export const fancyTimeFormatWithHMS = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h";
    }
    if (mins > 0) {
        ret += " " + mins + "m";
    }
    if (secs > 0 && hrs === 0) {
        ret += " " + secs + "s";
    }
    return ret.trim();
}

export const fancyTimeFormatWithHM = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs === 1) {
        ret += "" + hrs + " hr";
    } else if (hrs > 1) {
        ret += "" + hrs + " hrs";
    }

    if (mins === 1) {
        ret += " " + mins + " min";
    } else if (mins > 1) {
        ret += " " + mins + " mins";
    }
    return ret.trim();
};

export const getDateString = (publishedDate) => {
    return dayjs(publishedDate).format('MMM D, YYYY');
};

export const isTooOld = (dateMillis, tooOldSecondsLimit) => {
    if (!dateMillis) {
        return true;
    }
    const diffSeconds = (Date.now() - dateMillis) / 1000;
    return diffSeconds > tooOldSecondsLimit;
};