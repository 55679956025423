import { useEffect, useState, useRef, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import {
    setSearchText,
    setTrack,
} from '../redux/actions/dataActions';

import { setLastListen } from '../redux/actions/userActions';

// Components.
import ButtonTipPopup from './ButtonTipPopup';
import ProfilePicture from './ProfilePicture';
import SearchTranscript from './SearchTranscript'
import Sections from './Sections';
import ShareTrackDrawer from './ShareTrackDrawer';
import SyncTranscriptAudio from './SyncTranscriptAudio';
import TrackActions from './TrackActions';
import Transcript from './Transcript'

import { loadTrack } from "./audioHelper"
import { speakerImageSrc, speakerName } from './speakerHelper'

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'

import { getAnalytics, logEvent } from "firebase/analytics";

// MUI components.
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';

import useWindowDimensions from './windowHelper';

import { ACCENT_COLOR, speakerColor } from '../constants/colors';
import {
    Close,
    FastForwardRounded,
    FastRewindRounded,
    SkipNext,
    SkipPrevious,
} from '@material-ui/icons';

// TODO remove package from npm?
// import { useSwipeable } from 'react-swipeable';

import { fancyTimeFormat, fancyTimeFormatWithHMS, getDateString } from './timeHelper';

import {
    PLAYBACK_RATE,
    TIP_SECTION_NEXT,
    TIP_SECTION_PREV,
    TIP_SECTIONS_VIEW,
} from '../constants/local_storage_constants';
import EntityInfoDrawer from './EntityInfoDrawer';
import StartSecondsDrawer from './StartSecondsDrawer';
import CloseFooter from './CloseFooter';
import PlayPauseBar from './PlayPauseBar';
import { eventLog } from './eventHelper';
import axios from 'axios';
import { largeSourceImage } from './trackHelper';


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));

// TODO rename track player?
const AudioPlayer = ({
    close,
    source,
    trackId,

    // Redux.
    authenticated,
    setLastListen,
    setSearchText,
    setTrack,
    track,
}) => {
    const navigate = useNavigate();

    const audio = document.getElementById('audio');
    const sectionsContainerRef = useRef(null);
    const transcriptContainerRef = useRef(null);
    const topContentRef = useRef(null);
    const { height } = useWindowDimensions();

    // TODO have one curtime for audio and save local cur time.
    const [curTime, setCurTime] = useState(0);
    const [currentSpeaker, setCurrentSpeaker] = useState(null);
    const [localCurTime, setLocalCurTime] = useState(0);
    const [duration, setDuration] = useState(null);
    const [listenSeconds, setListenSeconds] = useState([]);
    const [localTrack, setLocalTrack] = useState(null);
    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const [openSearchTranscript, setOpenSearchTranscript] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [scrollToCurrentMessage, setScrollToCurrentMessage] = useState(false);
    const [selectedSpeakerId, setSelectedSpeakerId] = useState(null);
    const [resetPathnameOnClose, setResetPathnameOnClose] = useState(null);
    const [currentSectionElasedTime, setCurrentSectionElasedTime] = useState('');
    const [currentSectionTimeLeft, setCurrentSectionTimeLeft] = useState('');

    // Tips
    const [showNextSectionTip, setShowNextSectionTip] = useState(false);
    const [showPrevSectionTip, setShowPrevSectionTip] = useState(false);

    const analytics = getAnalytics();
    const [currentSection, setCurrentSection] = useState(0);

    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"));

    const fetchTrack = () => {
        axios
            .get(`/track/${trackId}`)
            .then((res) => {
                setLocalTrack(res.data);
                setTrack(res.data)
                // dispatch({
                //     type: SET_TRACK,
                //     payload: res.data
                // });
            })
            .catch((err) => {
                console.log(err);
                navigate('/missing')
                // dispatch({
                //   type: SET_ERRORS,
                //   payload: err.response.data
                // });
            });
    }

    useEffect(() => {
        if (!localTrack || !localTrack.sectionInfos) {
            return;
        }
        for (let i = localTrack.sectionInfos.length - 1; i >= 0; i--) {
            const sectionStartTime = localTrack.sectionInfos[i].startTime;
            if (localCurTime >= sectionStartTime) {
                setCurrentSection(Math.min(Math.max(i, 0), localTrack.sectionInfos.length - 1));
                return;
            }
        }

        // Default to 0.
        setCurrentSection(0);
    }, [localCurTime])

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!open) {
            checkAudioSource();
            audio.play().catch(function () { });
        } else {
            audio.pause();
        }
        setOpenPersonInfo(open);
    }

    const addListenTime = (seconds, forceWrite) => {
        if (!localTrack || !audio || localTrack.mp3URL !== audio.src || openPersonInfo) {
            return;
        }
        const floorSeconds = Math.floor(seconds);

        let newListenSeconds = listenSeconds;
        if (!listenSeconds.includes(floorSeconds)) {
            newListenSeconds = listenSeconds.concat([floorSeconds]);
            setListenSeconds(newListenSeconds);
        }

        if ((forceWrite || newListenSeconds.length > 10) && newListenSeconds.length > 0) {
            newListenSeconds.sort(function (a, b) { return a - b });
            var ranges = {};
            var currentStart = newListenSeconds[0];
            var currentLength = 0;
            for (var i = 1; i < newListenSeconds.length; i++) {
                if (newListenSeconds[i] === currentStart + currentLength + 1) {
                    currentLength += 1;
                } else {
                    if (currentLength > 0) {
                        ranges[currentStart] = currentLength;
                    }
                    currentStart = newListenSeconds[i];
                    currentLength = 0;
                }
            }
            if (currentLength > 0) {
                ranges[currentStart] = currentLength;
            }
            let highestEnd = 0;
            for (const [start, duration] of Object.entries(ranges)) {
                const end = parseInt(start) + parseInt(duration);
                logEvent(analytics, 'listen_time', {
                    trackId: localTrack.id,
                    startSecs: start,
                    endSecs: end,
                    durationSecs: duration,
                    playbackRate: audio.playbackRate,
                    playbackSecs: duration / audio.playbackRate,
                });
                if (end > highestEnd) {
                    highestEnd = end;
                }
            }
            if (authenticated) {
                setLastListen(localTrack.id, highestEnd);
            }
            setListenSeconds([]);
        }
    }

    const checkAudioSource = () => {
        if (localTrack) {
            setTrack(localTrack);
            if (audio.src !== localTrack.mp3URL) {
                loadTrack(localTrack.mp3URL, localCurTime, true);
            }
        }
    }

    useEffect(() => {
        if (!localTrack && track && track.id === trackId && track.pathname.startsWith('/track/')) {
            setLocalTrack(track);
        }
    }, [track])

    useEffect(() => {
        if (localTrack) {
            let sectionTimeEnd = localTrack.totalTimeSeconds;
            if (currentSection >= 0 && currentSection + 1 < localTrack.sectionInfos.length) {
                sectionTimeEnd = localTrack.sectionInfos[currentSection + 1].startTime;
            }
            const timeElapsed = curTime - localTrack.sectionInfos[currentSection].startTime
            const timeRemaining = Math.max(0, sectionTimeEnd - curTime);
            setCurrentSectionElasedTime(fancyTimeFormat(timeElapsed / audio.playbackRate));
            setCurrentSectionTimeLeft(`-${fancyTimeFormat(timeRemaining / audio.playbackRate)}`)
        }
    }, [curTime, currentSection])

    useEffect(() => {
        if (audio && localTrack && audio.src === localTrack.mp3URL) {
            setLocalCurTime(curTime);
        }
    }, [curTime])

    useEffect(() => {
        dayjs.extend(relativeTime);

        if (!localTrack) {
            if (track && track.id === trackId && track.pathname.startsWith('/track/')) {
                setLocalTrack(track);
            } else {
                fetchTrack()
            }
        }

        const setAudioTime = () => {
            setCurTime(audio.currentTime);
            if (!playing && !audio.paused) {
                setPlaying(true);
            }
            if (!duration) {
                setDuration(audio.duration);
            }
        }

        const onPause = (event) => {
            setPlaying(false);
            setScrollToCurrentMessage(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }

        // DOM listeners: update React state on DOM events
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, [])

    useEffect(() => {
        if (localTrack) {
            if (window.location.pathname === localTrack.pathname) {
                // Extra check to make sure the track is correct.
                // The delay for React to update the state causes the track to mismatch in some
                // cases (i.e. when hitting "continue conversation"), which causes loadTrack to
                // called with the wrong mp3 file.
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let start = 0;
                let startParam = urlParams.get('start');
                if (startParam) {
                    startParam = startParam.replace('s', '');
                    const startNumber = Number(startParam);
                    if (startNumber) {
                        start = startNumber
                    }
                }
                if (start > 0) {
                    setStartSecondsDrawerOpen(true);
                } else {
                    loadTrack(localTrack.mp3URL, 0, true);
                }
            } else {
                setResetPathnameOnClose(window.location.pathname);
                window.history.replaceState(null, '', `/track/${localTrack.id}`);
            }
            setScrollToCurrentMessage(false);
        }
    }, [localTrack])

    const [shareTrackDrawerOpen, setShareTrackDrawerOpen] = useState(false);
    const toggleShareTrackDrawerOpen = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            audio.pause();
        } else {
            audio.play().catch(function () { });
        }
        setShareTrackDrawerOpen(open);
    }

    const [startSecondsDrawerOpen, setStartSecondsDrawerOpen] = useState(false);
    const toggleStartSecondsDrawerOpen = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStartSecondsDrawerOpen(open);
    }

    useEffect(() => {
        if (scrollToCurrentMessage && audio.paused) {
            setTimeout(() => {
                audio.play().catch(function () { });
            }, 1000);
        }
    }, [scrollToCurrentMessage])

    useEffect(() => {
        addListenTime(curTime, false);
    }, [curTime])

    useEffect(() => {
        if (!playing) {
            addListenTime(audio.currentTime, true);
        }
    }, [playing])

    const seek = (timeSeconds) => {
        audio.currentTime = timeSeconds;
        audio.play().catch(function () { });
    }

    const exit = () => {
        audio.pause();
        if (close) {
            if (resetPathnameOnClose && resetPathnameOnClose !== null) {
                window.history.replaceState(null, '', resetPathnameOnClose);
            }
            close();
        } else {
            window.location = "/"
        }
    }

    const toggleSearchTranscript = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenSearchTranscript(open);
        if (!open) {
            setSearchText('');
        }
    }

    const playPauseClick = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play().catch(function () { });
        }
    }

    const bottomIconColor = 'rgb(0, 0, 0)';

    function sectionPrev() {
        if (!localStorage.TIP_SECTION_PREV && !showPrevSectionTip) {
            setShowPrevSectionTip(true);
            localStorage.setItem(TIP_SECTION_PREV, true);
        }

        for (let i = track.sectionInfos.length - 1; i >= 0; i--) {
            const sectionTime = track.sectionInfos[i].startTime;
            if (sectionTime < curTime - 4) {
                seek(sectionTime + 0.01);
                setScrollToCurrentMessage(true);
                return;
            }
        }
        seek(localTrack.transcript[0].timeSeconds);
        setScrollToCurrentMessage(true);
    }

    function sectionNext() {
        if (!localStorage.TIP_SECTION_NEXT && !showNextSectionTip) {
            setShowNextSectionTip(true);
            localStorage.setItem(TIP_SECTION_NEXT, true);
        }
        const section = localTrack.sectionInfos[currentSection];
        eventLog(10, {
            'trackId': localTrack.id,
            'sectionStartIndex': section.startIndex,
            'topicIds': section.topics,
        })

        for (let i = 0; i < localTrack.sectionInfos.length; i++) {
            const sectionTime = localTrack.sectionInfos[i].startTime;
            if (sectionTime > curTime + 1) {
                seek(sectionTime + 0.01);
                setScrollToCurrentMessage(true);
                return;
            }
        }
        seek(audio.duration - 0.1);
        setScrollToCurrentMessage(true);
    };

    const shouldDisplaySpeaker = (speakerId) => {
        for (let i = 0; i < localTrack.transcript.length; i++) {
            const message = localTrack.transcript[i];
            if (message.speakerId === speakerId) {
                return true;
            }
        }
        return false;
    }

    const openSpeakerInfo = (speakerId) => () => {
        if (speakerId != "unknown") {
            setSelectedSpeakerId(speakerId);
            togglePersonInfo(true)();
        }
    }

    const [showSectionOverview, setShowSectionOverview] = useState(false);

    const toggleSectionOverview = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            localStorage.setItem(TIP_SECTIONS_VIEW, true);
        }
        setShowSectionOverview(open);
    };

    const [showTrackActions, setShowTrackActions] = useState(false);
    const toggleTrackActions = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowTrackActions(open);
    };

    const closeNextOrPrevSectionTip = () => {
        if (showNextSectionTip) {
            setShowNextSectionTip(false);
        }
        if (showPrevSectionTip) {
            setShowPrevSectionTip(false);
        }
    }

    const topContent = (localTrack) => {
        return !localTrack ? <></> :
            <div className='trackAudioPlayerTopContainer' style={{ paddingTop: `${HEADER_HEIGHT}px` }} ref={topContentRef}>
                <div className='heading' style={{ padding: '16px' }}>
                    {localTrack.source.title}
                </div>

                <table style={{ padding: '0px 16px' }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: '16px' }}>
                                <img src={localTrack.source.imageURL} className='sourceImage' style={{ marginBottom: '6px', width: '44px', height: '44px' }} />
                            </td>
                            <td>
                                <div className='sourceName'>
                                    {localTrack.source.name}
                                </div>
                                <div style={{ fontSize: '14px', color: 'rgb(80, 80, 80)', paddingBottom: '6px' }}>
                                    {!duration ? '' :
                                        <span style={{ verticalAlign: 'middle' }}>
                                            {fancyTimeFormatWithHMS(duration)}
                                            <span style={{ padding: '6px' }}>‧</span>
                                        </span>
                                    }
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {getDateString(localTrack.publishedDate)}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style={{ padding: '10px 0px 16px' }} className='hideScrollbar horizontalScroll'>
                    {
                        localTrack.titleSpeakerIds.map((speakerId, speakerIndex) => (
                            <span key={speakerId} style={{ marginRight: '10px', marginLeft: speakerIndex === 0 ? '16px' : '0px' }}>
                                {!shouldDisplaySpeaker(speakerId) ? <></> :
                                    <ProfilePicture
                                        borderSize={2}
                                        imageSrc={speakerImageSrc(speakerId, localTrack.speakers)}
                                        color={speakerColor(speakerId, localTrack.speakers)}
                                        profileClick={openSpeakerInfo(speakerId)}
                                        size='100px'
                                        margin='10px 10px 0px 10px'
                                    />
                                }
                            </span>
                        ))
                    }
                </div>

                {/* <div style={{ borderTop: '1px solid rgb(242, 242, 242)', padding: '16px', margin: '16px 0px 0px 0px' }}>
                    <div className='sectionAndTranscriptHeading' >
                        Transcript
                    </div>
                    <div style={{ fontSize: '14px', color: 'rgb(80, 80, 80)' }}>
                        Generated with AI
                    </div>
                </div> */}
            </div>
    }

    const onSpeedClick = (val) => () => {
        audio.playbackRate = val;
        localStorage.setItem(PLAYBACK_RATE, val);
        setShowTrackActions(false);
    }

    const HEADER_HEIGHT = 44;

    return (
        <div className='hideScrollbar'>
            <EntityInfoDrawer
                open={openPersonInfo}
                onClose={togglePersonInfo(false)}
                speakerId={selectedSpeakerId} />

            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showNextSectionTip || showPrevSectionTip}
                onClose={closeNextOrPrevSectionTip}
            >
                <ButtonTipPopupRef close={closeNextOrPrevSectionTip} content={(
                    <div>
                        <div>
                            {showNextSectionTip ?
                                <SkipNext style={{ fontSize: '80px' }} />
                                :
                                <SkipPrevious style={{ fontSize: '80px' }} />
                            }
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            {showNextSectionTip ?
                                'Next Section'
                                :
                                'Previous Section'
                            }
                        </div>
                    </div>
                )} />
            </Modal>

            <div className="container" style={{ height: window.innerHeight }} >
                <div className='horizontalCenter'>
                    <div ref={transcriptContainerRef}
                        className='trackTranscriptContainer hideScrollbar'
                    >
                        <div className='horizontalCenter' style={{ background: 'transparent' }}>
                            <div style={{ background: 'white' }}>
                                {openPersonInfo || openSearchTranscript || !localTrack ?
                                    <div className='glow'>
                                        <div style={{ padding: '16px', overflowY: 'auto', paddingTop: `${HEADER_HEIGHT}px` }}>
                                            {!!source ?
                                                <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                                                    <div>
                                                        <img src={largeSourceImage(source.imageURL)} style={{ borderRadius: '8px', width: '220px' }} />
                                                    </div>
                                                    <div style={{ margin: '20px 0px 40px', fontSize: '16px', fontWeight: '500' }}>
                                                        {source.title}
                                                    </div>

                                                    <div className="loadingSpinner"></div>
                                                </div>
                                                :
                                                <div>
                                                    <div style={{ textAlign: 'center', padding: '80px 20px' }}>
                                                        <div style={{ padding: '20px', fontSize: '14px', fontWeight: '500', color: 'rgb(120, 120, 120)' }}>
                                                            Loading Episode...
                                                        </div>
                                                        <div className="loadingSpinner"></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Transcript
                                        autoscrollPaddingBottom={280}
                                        topContent={topContent(localTrack)}
                                        currentSection={currentSection}
                                        curTime={curTime}
                                        isClipOrPreview={false}
                                        isCurrentViewport={true}
                                        openSpeakerInfo={openSpeakerInfo}
                                        playing={playing}
                                        scrollToCurrentMessage={scrollToCurrentMessage}
                                        setScrollToCurrentMessage={setScrollToCurrentMessage}
                                        setCurrentSpeaker={setCurrentSpeaker}
                                        scrollToTopTopPadding={HEADER_HEIGHT}
                                        transcriptContainerRef={transcriptContainerRef}
                                        track={localTrack}
                                    />
                                }
                            </div>
                        </div>

                        <SyncTranscriptAudio
                            bottom='66px'
                            currentTime={curTime}
                            scrollToCurrentMessage={scrollToCurrentMessage}
                            setScrollToCurrentMessage={setScrollToCurrentMessage}
                            track={localTrack}
                        />
                    </div>

                    <Drawer
                        PaperProps={{
                            sx: {
                                borderRadius: '10px 10px 0px 0px'
                            }
                        }}
                        anchor='bottom' open={openSearchTranscript} onClose={toggleSearchTranscript(false)}>
                        <div className='horizontalCenter' style={{ height: height - 30 }}>
                            <SearchTranscript onMessageTimeClick={seek} curTime={curTime} close={toggleSearchTranscript(false)} />
                        </div>
                    </Drawer>

                    <Drawer
                        PaperProps={{
                            sx: {
                                borderRadius: '10px 10px 0px 0px'
                            }
                        }}
                        anchor='bottom' open={showTrackActions} onClose={toggleTrackActions(false)}>
                        <div className='horizontalCenter hasCloseFooter'>
                            <div style={{ padding: '10px 0px 20px' }}>
                                <TrackActions
                                    authenticated={authenticated}
                                    setOpenSearchTranscript={setOpenSearchTranscript}
                                    track={localTrack}
                                    onShareClick={toggleShareTrackDrawerOpen(true)}
                                    onSpeedClick={onSpeedClick}
                                    showLabels={true} />
                                <CloseFooter onClick={toggleTrackActions(false)} />
                            </div>
                        </div>
                    </Drawer>

                    <StartSecondsDrawer
                        open={startSecondsDrawerOpen}
                        close={toggleStartSecondsDrawerOpen(false)}
                        track={localTrack}
                    />

                    <ShareTrackDrawer
                        open={shareTrackDrawerOpen}
                        currentSection={currentSection}
                        close={toggleShareTrackDrawerOpen(false)}
                        track={localTrack}
                    />

                    <Drawer
                        PaperProps={{
                            sx: {
                                borderRadius: '20px 20px 0px 0px'
                            }
                        }}
                        anchor='bottom' open={showSectionOverview} onClose={toggleSectionOverview(false)}>
                        <div ref={sectionsContainerRef} style={{ overflowY: 'auto', height: height - 36 }}>
                            <div className='horizontalCenter' style={{ background: 'rgb(0, 0, 0, 0)' }}>
                                <Sections playing={playing} track={localTrack} currentSectionTimeLeft={currentSectionTimeLeft} currentSection={currentSection} close={toggleSectionOverview(false)} sectionsContainerRef={sectionsContainerRef} curTime={localCurTime} />
                            </div>
                        </div>
                    </Drawer>

                    <div className='header'>
                        <div className='horizontalCenter' >
                            <div className='blurBackground' style={{ textAlign: 'left', height: `${HEADER_HEIGHT}px` }}>
                                <span style={{ margin: '7px 20px', display: 'inline-block', verticalAlign: 'middle' }}>
                                    {!!currentSpeaker ?
                                        <button onClick={openSpeakerInfo(currentSpeaker.id)}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: '4px 8px 0px 0px'}}>
                                                            <ProfilePicture
                                                                borderSize={1}
                                                                imageSrc={currentSpeaker.imageURL}
                                                                color={speakerColor(currentSpeaker.id, localTrack.speakers)}
                                                                size='24px'
                                                                />
                                                        </td>
                                                        <td>
                                                            <span style={{ verticalAlign: 'middle', fontSize: '14px', fontWeight: '500' }}>
                                                                {currentSpeaker.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <img src={playing ? '/sound.gif' : '/sound.png'} style={{ marginLeft: '6px', verticalAlign: 'middle', width: '13px' }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </button> :
                                        <></>
                                    }
                                </span>
                                <button onClick={exit} style={{ float: 'right', marginRight: '6px', width: `${HEADER_HEIGHT}px`, height: `${HEADER_HEIGHT}px` }}>
                                    <Close style={{ verticalAlign: 'middle', fontSize: '24px' }} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {!localTrack ? <></> :
                        <div>
                            {!scrollToCurrentMessage ? <></> :
                                <div className='sectionsControlContainer'>
                                    <div className='bottomBar' style={{ height: '90px' }}>
                                        <div style={{ textAlign: 'center', paddingTop: '8px' }}>
                                            <button style={{ width: '100px', color: 'rgb(120, 120, 120)', textAlign: 'left', fontSize: '13px', padding: '0px 13px', float: 'left' }} onClick={sectionPrev}>
                                                {currentSectionElasedTime}
                                            </button>
                                            <button style={{ fontSize: '13px', color: 'rgb(120, 120, 120)', verticalAlign: 'top' }} onClick={toggleSectionOverview(true)}>
                                                {`Section ${currentSection + 1}`}
                                            </button>
                                            <button style={{ width: '100px', textAlign: 'right', fontSize: '13px', color: 'rgb(120, 120, 120)', padding: '0px 13px', float: 'right' }} onClick={sectionPrev}>
                                                {currentSectionTimeLeft}
                                            </button>
                                        </div>
                                        <div>
                                            <button style={{ padding: '4px', float: 'left' }} onClick={sectionPrev}>
                                                <FastRewindRounded style={{ padding: '2px', color: bottomIconColor, fontSize: '44px', verticalAlign: 'middle' }} />
                                            </button>
                                            <button onClick={toggleSectionOverview(true)}>
                                                <span className='sectionsLabelContainer'>
                                                    {localStorage.TIP_SECTIONS_VIEW ?
                                                        <div className='sectionsLabelText'>
                                                            {localTrack.sectionInfos[currentSection].title}
                                                        </div>
                                                        :
                                                        <div className='animate__animated animate__zoomIn' style={{ lineHeight: 0, padding: '8px 30px', border: `1px solid ${ACCENT_COLOR}50`, borderRadius: '30px', color: ACCENT_COLOR }}>
                                                            <span style={{ fontSize: '15px', fontWeight: '600', verticalAlign: 'middle' }}>
                                                                View Sections
                                                            </span>
                                                        </div>
                                                    }
                                                </span>
                                            </button>
                                            <button style={{ padding: '4px', float: 'right' }} onClick={sectionNext}>
                                                <FastForwardRounded style={{ padding: '2px', color: bottomIconColor, fontSize: '44px', verticalAlign: 'middle' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='trackBottomControls' style={{ paddingTop: scrollToCurrentMessage ? '24px' : '12px' }}>
                                <PlayPauseBar onMoreClick={toggleTrackActions(true)} />
                            </div>
                        </div>
                    }
                </div >
            </div>
        </div>
    );
};

AudioPlayer.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    track: state.data.track,
})

const mapActionsToProps = {
    setLastListen,
    setSearchText,
    setTrack,
}

export default connect(mapStateToProps, mapActionsToProps)(AudioPlayer);