// User reducer types
export const ADD_SAVED_TRACK_IDS = 'ADD_SAVE_TRACK_IDS';
export const ADD_SPEAKER_SUBSCRIBE_IDS = 'ADD_SPEAKER_SUBSCRIBE_IDS';
export const FOR_ME_LOADING_COUNT_INCREASE = 'FOR_ME_LOADING_COUNT_INCREASE';
export const FOR_ME_LOADING_COUNT_DECREASE = 'FOR_ME_LOADING_COUNT_DECREASE';
export const LOAD_LATEST_SUBSCRIED = 'LOAD_LATEST_SUBSCRIED'
export const LOAD_RECENTLY_PLAYED = 'LOAD_RECENTLY_PLAYED'
export const LOAD_RECENTLY_SAVED = 'LOAD_RECENTLY_SAVED'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_FOR_ME = 'SET_FOR_ME';
export const SET_GET_STARTED_TOPICS_STATUS_TRUE = 'SET_GET_STARTED_TOPICS_STATUS_TRUE';
export const SET_LATEST_SUBSCRIED = 'SET_LATEST_SUBSCRIED';
export const SET_PODCAST_SUBSCRIBE_STATUS = 'SET_PODCAST_SUBSCRIBE_STATUS';
export const SET_RECENTLY_PLAYED = 'SET_RECENTLY_PLAYED';
export const SET_RECENTLY_SAVED = 'SET_RECENTLY_SAVED';
export const SET_SPEAKER_SUBSCRIBE_STATUS = 'SET_SPEAKER_SUBSCRIBE_STATUS';
export const SET_SAVE_TRACK_STATUS = 'SET_SAVE_TRACK_STATUS';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';

// Data reducer types
export const LOAD_SUGGESTED_TOPICS = 'LOAD_SUGGESTED_TOPICS';
export const SET_NEXT_CLIPS_INFO = 'SET_NEXT_CLIPS_INFO';
export const SET_NEXT_PREVIEWS_INFO = 'SET_NEXT_PREVIEWS_INFO';
export const SET_RELATED_TOPIC_CLIPS = 'SET_RELATED_TOPIC_CLIPS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SUGGESTED_TOPICS = 'SET_SUGGESTED_TOPICS';
export const SET_TRACK = 'SET_TRACK';
export const SHUFFLE_SUGGESTED_TOPICS = 'SHUFFLE_SUGGESTED_TOPICS';
