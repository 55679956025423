import axios from 'axios';

import {
    ADD_SAVED_TRACK_IDS,
    ADD_SPEAKER_SUBSCRIBE_IDS,
    FOR_ME_LOADING_COUNT_INCREASE,
    FOR_ME_LOADING_COUNT_DECREASE,
    LOAD_LATEST_SUBSCRIED,
    LOAD_RECENTLY_PLAYED,
    LOAD_RECENTLY_SAVED,
    SET_AUTHENTICATED,
    SET_FOR_ME,
    SET_GET_STARTED_TOPICS_STATUS_TRUE,
    SET_LATEST_SUBSCRIED,
    SET_PODCAST_SUBSCRIBE_STATUS,
    SET_RECENTLY_PLAYED,
    SET_RECENTLY_SAVED,
    SET_SAVE_TRACK_STATUS,
    SET_SPEAKER_SUBSCRIBE_STATUS,
    SET_UNAUTHENTICATED,
    SET_USER,
} from '../types';

import { setFirebaseToken, validTokenExists } from '../../firebaseHelper'


export const loginUser = (token, refreshToken, newUserDetails) => (dispatch) => {
    setFirebaseToken(token, refreshToken);
    dispatch({ type: SET_AUTHENTICATED });
    axios
        .post('/login', newUserDetails)
        .then((res) => {
            dispatch({
                type: SET_USER,
                payload: res.data
            });
            // dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            // dispatch({
            //   type: SET_ERRORS,
            //   payload: err.response.data
            // });
        });

    
};

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('FirebaseIdToken');
    localStorage.removeItem('FirebaseRefreshToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
};


export const addSpeakerSubscribeIds = (speakerIds) => (dispatch) => {
    dispatch({
        type: ADD_SPEAKER_SUBSCRIBE_IDS,
        payload: {
            speakerIds: speakerIds
        }
    });
};


// Latest fetch of saved track ids may contain new track ids.
export const addSavedTrackIds = (trackIds) => (dispatch) => {
    dispatch({
        type: ADD_SAVED_TRACK_IDS,
        payload: {
            trackIds: trackIds
        }
    });
};

export const updateForMe = (forMePreviewsData) => (dispatch) => {
    dispatch({
        type: SET_FOR_ME,
        payload: forMePreviewsData,
    });
};


export const loadRecentlyPlayed = () => (dispatch) => {
    dispatch({
        type: LOAD_RECENTLY_PLAYED,
        payload: null,
    });
    validTokenExists()
        .then(() => {
            axios
                .post('/recently_played', {})
                .then((res) => {
                    dispatch({
                        type: SET_RECENTLY_PLAYED,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: SET_RECENTLY_PLAYED,
                        payload: null,
                    });
                    console.log(err)
                });
        })
        .catch((e) => {
            dispatch({
                type: SET_RECENTLY_PLAYED,
                payload: null,
            });
            console.log(e);
        });
}


export const loadRecentlySaved = () => (dispatch) => {
    dispatch({
        type: LOAD_RECENTLY_SAVED,
        payload: null,
    });
    validTokenExists()
        .then(() => {
            axios
                .post('/saved_tracks', {})
                .then((res) => {
                    dispatch({
                        type: SET_RECENTLY_SAVED,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: SET_RECENTLY_SAVED,
                        payload: null,
                    });
                    console.log(err)
                });
        })
        .catch((e) => {
            dispatch({
                type: SET_RECENTLY_SAVED,
                payload: null,
            });
            console.log(e);
        });
}


export const forMeLoadingCountIncrease = () => (dispatch) => {
    dispatch({
        type: FOR_ME_LOADING_COUNT_INCREASE,
        payload: null,
    });
}


export const forMeLoadingCountDecrease = () => (dispatch) => {
    dispatch({
        type: FOR_ME_LOADING_COUNT_DECREASE,
        payload: null,
    });
}

export const setGetStartedTopicsStatusTrue = () => (dispatch) => {
    dispatch({
        type: SET_GET_STARTED_TOPICS_STATUS_TRUE,
        payload: null,
    })
}


export const loadForMePreviews = () => (dispatch) => {
    dispatch({
        type: FOR_ME_LOADING_COUNT_INCREASE,
        payload: null,
    });
    validTokenExists()
        .then(() => {
            axios
                .post('/for_me_previews', {})
                .then((res) => {
                    dispatch({
                        type: FOR_ME_LOADING_COUNT_DECREASE,
                        payload: null,
                    });
                    dispatch({
                        type: SET_FOR_ME,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: FOR_ME_LOADING_COUNT_DECREASE,
                        payload: null,
                    });
                    dispatch({
                        type: SET_FOR_ME,
                        payload: null,
                    });
                    console.log(err)
                });
        })
        .catch((e) => {
            dispatch({
                type: FOR_ME_LOADING_COUNT_DECREASE,
                payload: null,
            });
            dispatch({
                type: SET_FOR_ME,
                payload: null,
            });
            console.log(e);
        });
};

export const loadLatestSubscribed = () => (dispatch) => {
    dispatch({
        type: LOAD_LATEST_SUBSCRIED,
        payload: null,
    });
    validTokenExists()
        .then(() => {
            axios
                .post('/latest_subscriptions', {})
                .then((res) => {
                    dispatch({
                        type: SET_LATEST_SUBSCRIED,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: SET_LATEST_SUBSCRIED,
                        payload: null,
                    });
                    console.log(err)
                });
        })
        .catch((e) => {
            dispatch({
                type: SET_LATEST_SUBSCRIED,
                payload: null,
            });
            console.log(e);
        });
}

export const setLastListen = (trackId, seconds) => (dispatch) => {
    if (seconds < 10) {
        return;
    }
    validTokenExists()
        .then(() => {
            axios
                .post('/last_listen', {
                    trackId: trackId,
                    seconds: seconds,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            console.log(e);
        });
};


export const setPodcastSubscribeStatus = (subscribeId, subscribeStatus) => (dispatch) => {
    validTokenExists()
        .then(() => {
            dispatch({
                type: SET_PODCAST_SUBSCRIBE_STATUS,
                payload: {
                    subscribeId: subscribeId,
                    subscribeStatus: subscribeStatus,
                }
            });
            axios
                .post('/subscribe', {
                    subscribeId: subscribeId,
                    subscribeStatus: subscribeStatus,
                    isSpeaker: false,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                        window.alert('Error: ' + res.data.error);
                        dispatch({
                            type: SET_PODCAST_SUBSCRIBE_STATUS,
                            payload: {
                                subscribeId: subscribeId,
                                subscribeStatus: !subscribeStatus,
                            }
                        });
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                    window.alert('Unexpected Error: ' + err.response.data.error);
                    dispatch({
                        type: SET_SPEAKER_SUBSCRIBE_STATUS,
                        payload: {
                            subscribeId: subscribeId,
                            subscribeStatus: !subscribeStatus,
                        }
                    });
                });
        })
        .catch((e) => {
            console.log(e);
        });
};


export const setSpeakerSubscribeStatus = (subscribeId, subscribeStatus) => (dispatch) => {
    validTokenExists()
        .then(() => {
            dispatch({
                type: SET_SPEAKER_SUBSCRIBE_STATUS,
                payload: {
                    subscribeId: subscribeId,
                    subscribeStatus: subscribeStatus,
                }
            });
            axios
                .post('/subscribe', {
                    subscribeId: subscribeId,
                    subscribeStatus: subscribeStatus,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                        window.alert('Error: ' + res.data.error);
                        dispatch({
                            type: SET_SPEAKER_SUBSCRIBE_STATUS,
                            payload: {
                                subscribeId: subscribeId,
                                subscribeStatus: !subscribeStatus,
                            }
                        });
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                    window.alert('Unexpected Error: ' + err.response.data.error);
                    dispatch({
                        type: SET_SPEAKER_SUBSCRIBE_STATUS,
                        payload: {
                            subscribeId: subscribeId,
                            subscribeStatus: !subscribeStatus,
                        }
                    });
                });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const saveTrack = (trackId, saveStatus) => (dispatch) => {
    validTokenExists()
        .then(() => {
            dispatch({
                type: SET_SAVE_TRACK_STATUS,
                payload: {
                    trackId: trackId,
                    saveStatus: saveStatus,
                }
            });
            axios
                .post('/save_track', {
                    trackId: trackId,
                    saveStatus: saveStatus,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                        window.alert('Error: ' + res.data.error);
                        dispatch({
                            type: SET_SAVE_TRACK_STATUS,
                            payload: {
                                trackId: trackId,
                                saveStatus: !saveStatus,
                            }
                        });
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                    window.alert('Unexpected Error: ' + err.response.data.error);
                    dispatch({
                        type: SET_SAVE_TRACK_STATUS,
                        payload: {
                            trackId: trackId,
                            saveStatus: !saveStatus,
                        }
                    });
                });
        })
        .catch((e) => {
            console.log(e);
        });
}