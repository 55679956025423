import { useState } from "react";
import { GET_STARTED_TOPIC_EMOJIS } from "../constants/get_started_topic_emojis";

const GetStartedTopics = ({
    topicsSelectionFinished,
}) => {

    const [selectedIndices, setSelectedIndices] = useState(new Set());

    const indexClick = (index) => () => {
        if (selectedIndices.has(index)) {
            selectedIndices.delete(index);
        } else {
            selectedIndices.add(index);
        }
        setSelectedIndices(new Set(selectedIndices));
    }

    const savePicks = () => {
        const selectedTopics = [];
        const unselectedTopics = [];
        for (let i = 0; i < GET_STARTED_TOPIC_EMOJIS.length; i++) {
            const topic = GET_STARTED_TOPIC_EMOJIS[i][1];
            if (selectedIndices.has(i)) {
                selectedTopics.push(topic);
            } else {
                unselectedTopics.push(topic);
            }
        }
        topicsSelectionFinished(selectedTopics, unselectedTopics);
    }

    return (
        <div>
            <div className='getStartedTopicsContainer'>
                {GET_STARTED_TOPIC_EMOJIS.map((val, i) => (
                    <button key={i} onClick={indexClick(i)} className={selectedIndices.has(i) ? 'getStartedTopicSelected' : 'getStartedTopic'}>
                        <div>
                            <div style={{ fontSize: '40px' }}>
                                {val[0]}
                            </div>
                            <div style={{ padding: '4px', fontSize: '14px' }}>
                                {val[1]}
                            </div>
                        </div>
                    </button>
                ))}
            </div>
            <div className='footer'>
                <div className='horizontalCenter'>
                    <div style={{ padding: '8px 20px', textAlign: 'center' }}>
                        {selectedIndices.size === 0 ?
                            <div style={{ height: '46px', paddingTop: '13px', fontSize: '16px' }}>
                                Pick 3 or more for the best results
                            </div>
                            :
                            <button onClick={savePicks} style={{ width: '100%', fontSize: '16px', textAlign: 'center' }}>
                                <div style={{ background: 'white', border: '1px solid rgb(224, 224, 224)', borderRadius: '20px', padding: '10px 60px', fontWeight: '500' }}>
                                    <span style={{ verticalAlign: 'middle' }}>
                                        Finish
                                    </span>
                                </div>
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default GetStartedTopics;
