export const TIP_GET_STARTED = 'TIP_GET_STARTED';
export const TIP_SAVED_EPISODE = 'TIP_SAVED_EPISODE';
export const TIP_SECTION_NEXT = 'TIP_SECTION_NEXT';
export const TIP_SECTION_PREV = 'TIP_SECTION_PREV';
export const TIP_SECTIONS_VIEW = 'TIP_SECTIONS_VIEW';
export const TIP_SUBSCRIBED = 'TIP_SUBSCRIBED';

export const PLAYBACK_RATE = 'PLAYBACK_RATE';

export const clearAllTipsOnLogout = () => {
    localStorage.clear();
}