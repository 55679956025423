// React state and router.
import { useEffect, useRef, useState } from 'react'

// Redux.
import { connect } from 'react-redux';
import {
    setNextPreviewsInfo,
} from '../redux/actions/dataActions';

import {
    loadForMePreviews,
} from '../redux/actions/userActions';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";


// Components.
import AudioPlayerDrawer from '../components/AudioPlayerDrawer';
import BookmarkButton from '../components/BookmarkButton';
import PreviewCard from '../components/PreviewCard';
import Loading from '../components/Loading';

// Component helpers.
import { loadTrack } from "../components/audioHelper"

// Constants.
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../components/windowHelper';
import { ChevronLeft, ChevronRight, HomeRounded, SkipPrevious } from '@material-ui/icons';
import { eventLog } from '../components/eventHelper';
import { AirlineStops } from '@mui/icons-material';
import { RESPONSIVE_LARGE_WIDTH } from '../constants/sizes';
import { Drawer } from '@mui/material';
import { getDateString } from '../components/timeHelper';
import { largeSourceImage } from '../components/trackHelper';

const _NUM_PREVIEWS_TO_PREFETCH = 1;

const Previews = ({
    // Redux state.
    authenticated,
    forMePreviewsData,
    forMeLoadingCount,
    nextPreviewsInfo,
    // TODO create new redux field preview that differs from track.
    // Currently both preview and track use "track" field.
    // A preview has additional fields such as "fullTrackId" and "relatedTopicPreviews"
    track,

    // Redux actions.
    loadForMePreviews,
    setNextPreviewsInfo,
}) => {
    const analytics = getAnalytics();
    const audio = document.getElementById('audio');
    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

    const [curTime, setCurTime] = useState(0);
    const [duration, setDuration] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    
    const finishedPreviewIds = useRef(new Set());

    const previewIndexInViewport = (previewIndex) => {
        setCurrentPreviewIndex(previewIndex);
        setTimeRemaining(NaN);
    }

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
    }

    useEffect(() => {
        if (!authenticated && !nextPreviewsInfo) {
            navigate('/');
        }

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'previews'
        });

        const setAudioTime = () => {
            setCurTime(audio.currentTime);
            const remaining = (audio.duration - audio.currentTime) / audio.playbackRate;
            setTimeRemaining(remaining);
            if (!playing && !audio.paused) {
                setPlaying(true);
            }
            if (!duration) {
                setDuration(audio.duration);
            }
        }

        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }

        // DOM listeners: update React state on DOM events
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    useEffect(() => {
        if (authenticated && !nextPreviewsInfo) {
            if (forMeLoadingCount === 0) {
                loadForMePreviews();
            }
        }
    }, [authenticated, nextPreviewsInfo])

    useEffect(() => {
        if (forMePreviewsData && !nextPreviewsInfo) {
            setNextPreviewsInfo(forMePreviewsData.previews);
        }
    }, [nextPreviewsInfo, forMePreviewsData])

    const { state } = useLocation();
    useEffect(() => {
        if (state && state.closeAllModal) {
            setLoading(true);
            previewsContainerScrollReset();
            setCurrentPreviewIndex(0);
            setTimeout(() => {
                setLoading(false);
                if (state) {
                    state.closeAllModal = false;
                }
            }, 500)
        }
    }, [nextPreviewsInfo])

    const _previewsScrollContainerId = 'previewsScrollContainer';
    const previewsContainerScrollReset = () => {
        const previewsScrollContainer = document.getElementById(_previewsScrollContainerId);
        if (previewsScrollContainer) {
            previewsScrollContainer.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }

    const playPreview = (preview) => {
        setCurTime(0);
        setTimeRemaining(preview.totalTimeSeconds / audio.playbackRate);
        loadTrack(preview.mp3URL, 0, true, true);
    }

    const nextPreview = () => {
        const nextIndex = currentPreviewIndex + 1;
        if (nextPreviewsInfo && nextIndex < nextPreviewsInfo.previewsInfo.length) {
            const nextElementId = nextPreviewsInfo.previewsInfo[nextIndex].id;
            const elem = document.getElementById(nextElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
            }
        }
    }

    const prevPreview = () => {
        let prevIndex = currentPreviewIndex - 1;
        if (nextPreviewsInfo && prevIndex >= 0 && prevIndex < nextPreviewsInfo.previewsInfo.length) {
            const prevElementId = nextPreviewsInfo.previewsInfo[prevIndex].id;
            const elem = document.getElementById(prevElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
            }
        }
    }

    const [showFullEpisodeContinueOrStart, setShowFullEpisodeContinueOrStart] = useState(false);
    const fullEpisodeClick = () => {
        if (!track) {
            return;
        }
        audio.pause();
        setShowFullEpisodeContinueOrStart(true);
    }

    const startFromBeginning = () => {
        playFullEpisode(0);
    }

    const continueListening = () => {
        playFullEpisode(track.previewStartTime + curTime)
    }

    const playFullEpisode = (startSeconds) => {
        setShowFullEpisodeContinueOrStart(false);
        const topicIds = [];
        for (let i = 0; i < track.relatedTopicPreviews.length; i++) {
            topicIds.push(track.relatedTopicPreviews[i].id);
        }

        eventLog(15, {
            'previewId': track.id,
            'speakerIds': track.titleSpeakerIds,
            'topicIds': topicIds,
        });
        logEvent(analytics, 'preview_card_continue_listening');
        loadTrack(track.trackMp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
    }

    useEffect(() => {
        if (track && currentPreviewIndex < nextPreviewsInfo.previewsInfo.length &&
            track.mp3URL === nextPreviewsInfo.previewsInfo[currentPreviewIndex].mp3URL) {
            if ((!isNaN(timeRemaining) && timeRemaining === 0)) {
                document.getElementById('clipfinished').play().catch(function () {
                    // Browser doesn't allow play until user click.
                });                
                nextPreview();
                setTimeout(() => {
                    finishedPreviewIds.current.add(nextPreviewsInfo.previewsInfo[currentPreviewIndex].id);
                }, 700);
            }
        }
    }, [timeRemaining, track])


    const previewFullEpisode = (
        <div style={{
            padding: '8px',
            borderRadius: '8px',
            background: '#FFFFFF81'
        }}>
            {!track || track.mp3URL !== audio.src ?
                <table
                    style={{ borderSpacing: 0, textAlign: 'left', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: '12px' }}>
                                <div className='loading' style={{ height: '42px', width: '36px', borderRadius: '4px' }} />
                            </td>
                            <td style={{ width: '100%' }}>
                                <div className='loading' style={{ height: '42px', width: '100%', borderRadius: '4px' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <div>
                    <table style={{ width: '100%', borderSpacing: 0, textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: '12px' }}>
                                    <button style={{ verticalAlign: 'middle' }} onClick={fullEpisodeClick}>
                                        <img alt='source' src={track.source.imageURL} className='sourceImage' />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ verticalAlign: 'middle', textAlign: 'left', width: '100%' }} onClick={fullEpisodeClick}>
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ fontSize: '12px', fontWeight: '500', color: 'rgb(80, 80, 80)' }}>
                                                Full Episode · {getDateString(track.publishedDate)}
                                            </div>
                                            <div className='sourceTitle'>
                                                {track.source.title}
                                            </div>
                                        </div>
                                    </button>
                                </td>
                                {authenticated ?
                                    <td style={{ minWidth: '40px', textAlign: 'right' }}>
                                        <BookmarkButton trackId={track.fullTrackId ?? track.id}
                                            buttonClassName='clipCardBookmarkButton'
                                            iconStyle={{ color: '#202124', fontSize: '25px' }} />
                                    </td>
                                    :
                                    <></>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )

    const homeClick = () => {
        audio.pause();
        navigate('/')
    }

    return (
        <div className="homeContainer menuBackground">

            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                source={track ? track.source : null}
                trackId={track ? track.fullTrackId ?? track.id : null} />

            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '10px 10px 0px 0px'
                    }
                }}
                anchor='bottom' open={showFullEpisodeContinueOrStart} onClose={() => { setShowFullEpisodeContinueOrStart(false) }}>
                {/* <div className='horizontalCenter' style={{ height: height - 30 }}>
                    <SearchTranscript onMessageTimeClick={seek} curTime={curTime} close={toggleSearchTranscript(false)} />
                </div> */}
                {!track ?
                    <div>
                        Unexpected Error.
                    </div>
                    :
                    <div style={{ padding: '30px 0px 30px' }}>
                        <div className='horizontalCenter'>
                            <div style={{ textAlign: 'center', padding: '0px 20px 30px' }}>
                                <div>
                                    <button onClick={() => { setShowFullEpisodeContinueOrStart(false) }}>
                                        <img src={largeSourceImage(track.source.imageURL)} style={{ borderRadius: '8px', width: '220px' }} />
                                    </button>

                                </div>
                                <div style={{ marginTop: '20px', fontSize: '16px', fontWeight: '500' }}>
                                    {track.source.title}
                                </div>
                                <div style={{ fontSize: '14px', paddingTop: '3px', color: 'rgb(120, 120, 120)' }}>
                                    <span>
                                        {getDateString(track.publishedDate)}
                                    </span>

                                </div>
                            </div>
                            <button onClick={startFromBeginning} style={{ width: '100%', padding: '6px 20px', }}>
                                <div style={{ textAlign: 'center', border: '1px solid rgb(224, 224, 224)', padding: '13px 20px', borderRadius: '8px' }}>
                                    <SkipPrevious style={{ fontSize: '24px', verticalAlign: 'middle', marginRight: '16px' }} />
                                    <span style={{ fontSize: '16px', fontWeight: '500', verticalAlign: 'middle' }}>
                                        Start From Beginning
                                    </span>
                                </div>
                            </button>
                            <button onClick={continueListening} style={{ width: '100%', padding: '6px 20px', }}>
                                <div style={{ textAlign: 'center', border: '1px solid rgb(224, 224, 224)', padding: '13px 20px', borderRadius: '8px' }}>
                                    <AirlineStops style={{ fontSize: '24px', verticalAlign: 'middle', marginRight: '16px' }} />
                                    <span style={{ fontSize: '16px', fontWeight: '500', verticalAlign: 'middle' }}>
                                        Continue Listening
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                }

            </Drawer >

            <div>
                {width < RESPONSIVE_LARGE_WIDTH ? <></> :
                    <div style={{ position: 'fixed', top: `${height / 2 - 56}px` }}>
                        <div style={{ display: 'inline-block', marginLeft: '-95px' }}>
                            <button disabled={currentPreviewIndex === 0} style={{ opacity: currentPreviewIndex === 0 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={prevPreview}>
                                <ChevronLeft style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: '600px' }}>
                            <button disabled={currentPreviewIndex === nextPreviewsInfo.previewsInfo.length - 1} style={{ opacity: currentPreviewIndex === nextPreviewsInfo.previewsInfo.length - 1 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={nextPreview}>
                                <ChevronRight style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                    </div>
                }

                <div className='main'>
                    <div id={_previewsScrollContainerId} className='hslider-container hideScrollbar horizontalScroll'>
                        {!nextPreviewsInfo || loading ?
                            <div><Loading /></div> :
                            <div>
                                {nextPreviewsInfo.previewsInfo.map((preview, index) => (
                                    <div key={preview.id} className='hslider-children' style={{ marginRight: index + 1 === nextPreviewsInfo.previewsInfo.length ? '30px' : '0px' }}>
                                        <PreviewCard
                                            shouldLoadTrack={currentPreviewIndex + _NUM_PREVIEWS_TO_PREFETCH >= index}
                                            preview={preview}
                                            previewIndexInViewport={previewIndexInViewport}
                                            finishedPreviewIds={finishedPreviewIds}
                                            playFullEpisode={playFullEpisode}
                                            currentTime={curTime}
                                            index={index}
                                            playPreview={playPreview}
                                            nextPreview={nextPreview}
                                            playing={playing}
                                            showAudioPlayerDrawer={showAudioPlayerDrawer}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

                <div className='footerNoBlur'>
                    <div className='horizontalCenter'>
                        <div style={{ padding: '8px' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <div className='bottomBar' style={{ width: '100%' }}>
                                                {previewFullEpisode}
                                            </div>
                                        </td>
                                        <td style={{ paddingLeft: '8px', paddingRight: '22px' }}>

                                            <button onClick={homeClick} className='bottomBar' style={{ height: '58px', width: '58px' }}>
                                                <HomeRounded style={{ verticalAlign: 'middle', fontSize: '25px' }} />
                                                {/* <img src='/icon.png' style={{ verticalAlign: 'middle', width: '24px' }} /> */}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    nextPreviewsInfo: state.data.nextPreviewsInfo,
    track: state.data.track,

    authenticated: state.user.authenticated,
    forMePreviewsData: state.user.forMePreviewsData,
    forMeLoadingCount: state.user.forMeLoadingCount,
})

const mapActionsToProps = {
    loadForMePreviews,
    setNextPreviewsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(Previews);