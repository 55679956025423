import SpeedActions from "./SpeedActions";
import PreviewShareButton from "./PreviewShareButton";

const PreviewActions = ({
    preview,
    onSpeedClick,

}) => {

    return <div>
        <SpeedActions onSpeedClick={onSpeedClick} />

        <div style={{ textAlign: 'center', padding: '10px' }}>
            <PreviewShareButton
                buttonStyle={{ verticalAlign: 'middle' }}
                iconStyle={{ fontSize: '24px', verticalAlign: 'middle' }}
                trackId={preview.fullTrackId}
                messageIndex={preview.messageIndex}
                title={preview.title}
                showLabel={true}
            />
        </div>

    </div>;
};

export default PreviewActions;