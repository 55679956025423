import { connect } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import {
    setNextPreviewsInfo,
    setTrack,
} from '../redux/actions/dataActions';

import { getAnalytics, logEvent } from "firebase/analytics";
import axios from 'axios';
import { loadTrack } from '../components/audioHelper';
import { shuffle } from 'lodash';
import ClipOrPreviewInfo from '../components/ClipOrPreviewInfo';
import { PauseRounded, PlayArrowRounded } from '@material-ui/icons';
import { LABEL_TAGLINE } from '../constants/labels';
import ClipLargePreviewLoading from '../components/ClipLargePreviewLoading';

const Preview = ({
    setNextPreviewsInfo,
    setTrack,
}) => {
    const audio = document.getElementById('audio');
    const navigate = useNavigate();
    let params = useParams();
    const analytics = getAnalytics();

    const [previewInfo, setPreviewInfo] = useState(null);
    const [preview, setPreview] = useState(null);
    const [playPreviewTapped, setPlayPreviewTapped] = useState(false);

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'preview'
        });
    }, [analytics]);

    const tapPlayPreview = () => {
        if (!playPreviewTapped) {
            loadTrack(previewInfo.mp3URL, 0);
            setPlayPreviewTapped(true);
        }
    }

    useEffect(() => {
        audio.pause();
        if (!!params.trackId && !!params.messageIndex) {
            axios
                .get(`/preview_info/${params.trackId}/${params.messageIndex}`)
                .then((res) => {
                    const data = res.data;
                    setPreviewInfo(data);
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/missing');
                });

            axios
                .get(`/preview/${params.trackId}/${params.messageIndex}`)
                .then((res) => {
                    const data = res.data;
                    setPreview(data);
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/missing');
                });
        }
    }, [params]);

    useEffect(() => {
        if (!!preview && playPreviewTapped) {
            navToPreviewCard(preview)();
        }
    }, [preview, playPreviewTapped]);

    const navToPreviewCard = (preview) => () => {
        if (preview) {
            setTrack(preview);
            const addedPreviewIds = new Set();
            const nextPreviews = [];
            addedPreviewIds.add(previewInfo.id);
            if (preview.relatedTopicPreviews) {
                for (let i = 0; i < preview.relatedTopicPreviews.length; i++) {
                    const previews = preview.relatedTopicPreviews[i].previews;
                    for (let j = 0; j < previews.length; j++) {
                        const p = previews[j];
                        if (!addedPreviewIds.has(p.id)) {
                            nextPreviews.push(p);
                            addedPreviewIds.add(p.id);
                        }
                    }
                }
            }
            setNextPreviewsInfo([previewInfo].concat(shuffle(nextPreviews)));
            navigate('/previews');
        }
    }

    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    const playPauseClick = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play();
        }
    }

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    return (
        <div className='container'>
            <div className='center' style={{ textAlign: 'center' }}>
                <div style={{ paddingBottom: '20px'  }}>
                    <a href='/'>
                        <div>
                            <img className='appName' src='/appname2.png' />
                        </div>
                        <div className='tagline'>
                            {LABEL_TAGLINE}
                        </div>
                    </a>
                </div>
                {!!previewInfo ? 
                <div style={{ paddingBottom: '20px' }}>
                    <div className='hslider-children' style={{ margin: '10px 0px' }}>
                        <ClipOrPreviewInfo clipOrPreview={previewInfo} playClipOrPreview={tapPlayPreview} index={0} indexInViewport={(x) => { }} />
                    </div>
                    <div>
                        {!playPreviewTapped ? <></> :
                            <div style={{ padding: '20px' }}>
                                <button onClick={playPauseClick}>
                                    <div>
                                        <div style={{ display: 'inline-block', width: '152px', padding: '10px', borderRadius: '20px', background: 'black', color: 'white' }}>
                                            {playing ?
                                                <PauseRounded style={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '20px',
                                                }} />
                                                :
                                                <PlayArrowRounded style={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '20px',
                                                }} />
                                            }
                                        </div>
                                    </div>
                                </button>
                                <div className='glow' style={{ paddingTop: '20px' }}>
                                    Loading transcript...
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                    <ClipLargePreviewLoading count={1}/>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    setNextPreviewsInfo,
    setTrack,
}

export default connect(mapStateToProps, mapActionsToProps)(Preview);