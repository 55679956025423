import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { useEffect, useRef, useState } from 'react'

import Message from './Message'
import Loading from './Loading'

import {
    getSpeakerData,
} from './speakerHelper'

import { speakerColor } from "../constants/colors";
import { getSectionTitle } from './sectionHelper';
import { loadTrack } from './audioHelper';

const Transcript = ({
    // From redux.
    authenticated,

    // Passed in.
    autoscrollPaddingBottom,
    curTime,
    currentSection,
    isClipOrPreview,
    isCurrentViewport,
    openSpeakerInfo,
    playing,
    scrollToCurrentMessage,
    scrollToTopTopPadding,
    setCurrentSpeaker,
    setScrollToCurrentMessage,
    showSpeakerDetails,
    topContent,
    transcriptContainerRef,
    track,
}) => {

    const [message, setMessage] = useState({});
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const scrollIntoViewInProgress = useRef(null);
    const oldScroll = useRef(0);
    const scrollTimeout = useRef(null);
    
    const _CURRENT_MESSAGE_INDEX_PAGE_SIZE_SECONDS = 60
    const currentMessageIndex = useRef(null);

    const audio = document.getElementById('audio');

    const setScrollToCurrentMessageWrapper = (value) => {
        setShowScrollTopButton(false);
        setScrollToCurrentMessage(value);
    }

    const scrollToTop = () => {
        scrollIntoViewHelper(getTranscriptTopId());
        setShowScrollTopButton(false);
    }

    useEffect(() => {
        const onScroll = (e) => {
            clearTimeout(scrollTimeout.current);
            // Set in progress to false once scroll finishes.
            // onScroll will continue updating scrollTimeout when scroll is in progress.
            // when scroll finishes, scrollIntoViewInProgress will be set to false.
            if (scrollIntoViewInProgress.current) {
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 300);
            } else {
                // If user manually scrolls, disable auto scrolling to current message.
                setScrollToCurrentMessage(false);
            }

            if (!scrollIntoViewInProgress.current) {
                var st = transcriptContainerRef.current.scrollTop;
                if (st > oldScroll.current) {
                    // downscroll code
                    setShowScrollTopButton(false);
                } else if (st < oldScroll.current) {
                    // scroll up
                    if (st > 200) {
                        setShowScrollTopButton(true);
                    } else {
                        setShowScrollTopButton(false);
                    }
                }
                oldScroll.current = st <= 0 ? 0 : st
            } else {
                setShowScrollTopButton(false);
            }
        }

        // DOM listeners: update React state on DOM events
        // window.addEventListener('scroll', onScroll);
        transcriptContainerRef.current.addEventListener('scroll', onScroll);

        // effect cleanup
        return () => {
            // clearTimeout(scrollTimeout.current);
            // window.removeEventListener('scroll', onScroll);
            const transcriptContainer = transcriptContainerRef.current;
            if (transcriptContainer) {
                transcriptContainer.removeEventListener('scroll', onScroll);
            }
        }
    });

    const createCurrentMessageIndex = () => {
        let paginatedIndex = {};
        track.transcript.forEach((message, index) => {
            const page = Math.floor(message.timeSeconds / _CURRENT_MESSAGE_INDEX_PAGE_SIZE_SECONDS);
            if (paginatedIndex[page] !== undefined) {
                paginatedIndex[page] = Math.max(paginatedIndex[page], index);
            } else {
                paginatedIndex[page] = index;
            }
        });
        return paginatedIndex;
    };

    useEffect(() => {
        currentMessageIndex.current = createCurrentMessageIndex();
    }, []);    

    const scrollIntoViewHelper = (elementId) => {
        // console.log('scrollIntoViewHelper ' + elementId + isCurrentViewport);
        if (!isCurrentViewport) {
            return
        }
        let elem = document.getElementById(elementId);
        if (isClipOrPreview && track && track.transcript && track.transcript[0].id === elementId) {
            elem = document.getElementById(getTranscriptTopId());
        }
        if (elem) {
            scrollIntoViewInProgress.current = true;
            elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
            scrollTimeout.current = setTimeout(function () {
                scrollIntoViewInProgress.current = false;
            }, 1000);
        } else {
            // console.log("missing id: " + elementId);
        }
    }

    // Scroll to current message when audio time changes.
    useEffect(() => {
        if (currentMessageIndex.current && track && !scrollIntoViewInProgress.current && isCurrentViewport && audio.src === track.mp3URL) {
            const page = Math.floor(curTime / _CURRENT_MESSAGE_INDEX_PAGE_SIZE_SECONDS);
            const highestPossibleIndex = currentMessageIndex.current[page];
            for (var i = highestPossibleIndex; i >= 0; i--) {
                var m = track.transcript[i]
                if (m.timeSeconds < curTime || i === 0) {
                    const shouldScroll = scrollToCurrentMessage && m.id !== message.id;
                    if (shouldScroll) {
                        scrollIntoViewHelper(m.id);
                    }
                    setMessage(m);
                    break;
                }
            }
        }
    }, [curTime, track])

    useEffect(() => {
        if (!!message) {
            setCurrentSpeaker(getSpeakerData(message.speakerId, track.speakers));
        } else {
            setCurrentSpeaker(null);
        }
    }, [message])

    // Scroll to current message when button is clicked.
    useEffect(() => {
        if (scrollToCurrentMessage) {
            if (message && message.id) {
                scrollIntoViewHelper(message.id);
            }
        }
    }, [scrollToCurrentMessage])

    const seekFn = (timeSeconds) => () => {
        setMessage({});
        if (audio.src !== track.mp3URL) {
            loadTrack(track.mp3URL, timeSeconds)
            audio.play().catch(function () {
                // Browser doesn't allow play until user click.
            });
        } else {
            seek(timeSeconds);
        }
    }

    const seek = (timeSeconds) => {
        setScrollToCurrentMessageWrapper(true);
        audio.play().catch(function () { });
        audio.currentTime = timeSeconds;
    }

    function isNewSpeaker(index, messages) {
        if (index === 0) {
            return true;
        }
        return messages[index - 1].speakerId !== messages[index].speakerId;
    }

    const messagesForTrack = (track, start, numMessages) => {
        const messages = [];
        for (let i = start; i < start + numMessages; i++) {
            const message = track.transcript[i];
            messages.push(message);
        }
        return messages;
    }

    const getTranscriptTopId = () => {
        return "transcriptTop" + track.id;
    }


    return (
        <div style={{ width: '100%' }}>
            <div id={getTranscriptTopId()}></div>
            <div>
                <div>
                    {topContent}
                    <div style={{ paddingBottom: isClipOrPreview ? '20px' : '100px' }}>
                        {track.sectionInfos.map((section, sectionIndex) => (
                            <div key={sectionIndex}>
                                {track.sectionInfos.length < 2 ? <></> :
                                    <div style={{
                                        opacity: !scrollToCurrentMessage || currentSection === sectionIndex || !playing ? 1.0 : 0.55,
                                    }}>
                                        <div className='section'>
                                            <button className='sectionButtonInTranscript' onClick={seekFn(section.startTime)}>
                                                <div>
                                                    <span style={{ color: 'rgb(120, 120, 120)', fontWeight: '600', fontSize: '13px', verticalAlign: 'middle' }}>Section {sectionIndex + 1}</span>
                                                </div>
                                                <div className='sectionTitleInTranscript'>
                                                    {getSectionTitle(section)}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    messagesForTrack(track, section.startIndex, section.numMessages).map((m, index) => (
                                        <div key={m.id}>
                                            {false ? <div id={m.id}></div> :
                                                <div id={m.id} style={{
                                                    // add padding/margin so autoscroll doesn't scroll all the
                                                    // way to the bottom and leaves some buffer.
                                                    paddingBottom: `${autoscrollPaddingBottom}px`,
                                                    marginBottom: `-${autoscrollPaddingBottom}px`,
                                                }}>
                                                    {playing && m.id === message.id && curTime > 0 ?
                                                        <Message
                                                            authenticated={authenticated}
                                                            message={m}
                                                            isCurrent={true}
                                                            curTime={curTime}
                                                            openSpeakerInfo={openSpeakerInfo}
                                                            playing={playing}
                                                            scrollToCurrentMessage={scrollToCurrentMessage}
                                                            seekFn={seekFn}
                                                            borderColor={showSpeakerDetails ? speakerColor(m.speakerId, track.speakers) : ''}
                                                            speaker={getSpeakerData(m.speakerId, track.speakers)}
                                                            showSpeakerDetails={showSpeakerDetails && isNewSpeaker(index, messagesForTrack(track, section.startIndex, section.numMessages))}
                                                            isLastMessage={index + 1 === section.numMessages}
                                                        />
                                                        :
                                                        // Same as above but don't refer to curTime to allow for faster updates.
                                                        <Message
                                                            authenticated={authenticated}
                                                            message={m}
                                                            isCurrent={false}
                                                            openSpeakerInfo={openSpeakerInfo}
                                                            playing={playing}
                                                            scrollToCurrentMessage={scrollToCurrentMessage}
                                                            seekFn={seekFn}
                                                            borderColor={showSpeakerDetails ? speakerColor(m.speakerId, track.speakers) : ''}
                                                            speaker={getSpeakerData(m.speakerId, track.speakers)}
                                                            showSpeakerDetails={showSpeakerDetails && isNewSpeaker(index, messagesForTrack(track, section.startIndex, section.numMessages))}
                                                            isLastMessage={index + 1 === section.numMessages}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: showScrollTopButton ? 'block' : 'none', width: '100%', maxWidth: '540px', textAlign: 'right', position: 'absolute', right: '0px', top: scrollToTopTopPadding }}>
                <button className='blurBackground' style={{ boxShadow: '0 0px 6px rgba(0, 0, 0, 0.14)', fontSize: '24px', width: '44px', height: '44px', borderRadius: '50%', margin: '12px 12px' }} onClick={scrollToTop}>
                    🔝
                </button>
            </div>
        </div >
    );
};

Transcript.defaultProps = {
    showSpeakerDetails: true,
}

Transcript.propTypes = {
    openSpeakerInfo: PropTypes.func.isRequired,
    showSpeakerDetails: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Transcript);