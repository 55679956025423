import { getAnalytics, logEvent } from "firebase/analytics";
import { shuffle } from 'lodash';
import { eventLog } from './eventHelper';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import { setNextPreviewsInfo } from "../redux/actions/dataActions";
import { setSpeakerSubscribeStatus } from '../redux/actions/userActions';
import ProfilePicture from "./ProfilePicture";
import axios from "axios";
import { useState } from "react";
import { WebStoriesOutlined } from "@mui/icons-material";
import { loadTrack } from "./audioHelper";
import { ChevronRightRounded } from "@material-ui/icons";
import { Drawer } from "@mui/material";
import CloseFooter from "./CloseFooter";


const SpeakerHeader = ({
    summary,
    speaker,
    previews,
    onSpeakerClick,
    // Redux.
    authenticated,
    setNextPreviewsInfo,
    setSpeakerSubscribeStatus,
    speakerSubscribeIds,

}) => {

    const analytics = getAnalytics();
    const navigate = useNavigate();

    const subscribeClick = () => {
        if (!authenticated) {
            return;
        }
        logEvent(analytics, !currentSubscribeStatus ? 'subscribe_true' : 'subscribe_false');
        setSpeakerSubscribeStatus(speaker.id, !currentSubscribeStatus);
    }

    const isSubscribed = () => {
        const speakerSubscribeIdSet = new Set(speakerSubscribeIds);
        return speakerSubscribeIdSet.has(speaker.id);
    }

    const currentSubscribeStatus = isSubscribed();

    const [loadingPreviews, setLoadingPreviews] = useState(false);

    const playPreviewsClick = () => {
        if (previews && previews.length > 0) {
            const shuffledPreviews = shuffle(previews);
            playPreviews(shuffledPreviews)
        } else {
            setLoadingPreviews(true);
            axios
                .get(`/speaker/${encodeURIComponent(speaker.id)}`)
                .then((res) => {
                    setLoadingPreviews(false);
                    if (res.data && res.data.previews && res.data.previews.length > 0) {
                        const shuffledPreviews = shuffle(res.data.previews);
                        playPreviews(shuffledPreviews)
                    } else {
                        window.alert('No previews found.');
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const playPreviews = (previewsToPlay) => {
        loadTrack(previewsToPlay[0].mp3URL, 0)
        setNextPreviewsInfo(previewsToPlay);
        document.getElementById('audio').pause();
        navigate('/previews', { state: { closeAllModal: true } });

        eventLog(11, {
            'speakerId': speaker.id
        });
    }

    const [openFullSummary, setOpenFullSummary] = useState(false);

    const toggleFullSummary = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!summary) {
            return;
        }
        setOpenFullSummary(open);
    }

    const cleanSummary = (summary) => {
        return summary.replace(/ *\([^)]*\)/g, "");
    };

    return (
        <div>
            <Drawer anchor='bottom' open={openFullSummary} onClose={toggleFullSummary(false)}>
                {!summary ? <></> :
                    <div className='horizontalCenter' style={{ height: '100vh' }}>
                        <div className='hasCloseFooter'>
                            <div style={{ padding: '20px' }}>
                                <div className='heading'>
                                    Wikipedia
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {cleanSummary(summary)}
                                </div>
                                
                            </div>
                        </div>
                        <CloseFooter onClick={toggleFullSummary(false)} />
                    </div>
                }
            </Drawer>
            <div style={{ padding: '16px 20px 10px' }}>
                <table>
                    <tbody>
                        {!onSpeakerClick ?
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <div className='speakerNameLarge'>
                                        {speaker.name}
                                    </div>
                                    <div className='speakerDescriptionTwoLines'>
                                        {speaker.description}
                                    </div>
                                </td>
                                <td style={{ minWidth: '76px', textAlign: 'right' }}>
                                    <ProfilePicture
                                        borderSize={2}
                                        imageSrc={speaker.imageURL}
                                        color={'rgb(210, 210, 210)'}
                                        profileClick={null}
                                        size='60px'
                                    />
                                </td>
                            </tr>
                            :

                            <tr>
                                <td style={{ minWidth: '76px', textAlign: 'left' }}>
                                    <ProfilePicture
                                        borderSize={2}
                                        imageSrc={speaker.imageURL}
                                        color={'rgb(210, 210, 210)'}
                                        profileClick={onSpeakerClick}
                                        size='60px'
                                    />
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button onClick={onSpeakerClick} style={{ textAlign: 'left' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <div className='speakerNameLarge'>
                                                <span style={{ verticalAlign: 'middle' }}>
                                                    {speaker.name}
                                                </span>
                                                <ChevronRightRounded style={{ color: 'rgb(80, 80, 80)', fontSize: '34px', verticalAlign: 'middle' }} />

                                            </div>
                                            <div className='speakerDescriptionTwoLines'>
                                                {speaker.description}
                                            </div>
                                        </div>
                                    </button>
                                </td>

                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {!summary ?
                <></>
                :
                <div>
                    <div style={{ padding: '10px 20px' }}>
                        <button
                            onClick={toggleFullSummary(true)}
                            style={{ textAlign: 'left' }}>
                            <div className='podcastSummary'>
                                {summary}
                            </div>
                        </button>
                    </div>
                </div>
            }
            <table style={{ padding: '4px 16px 0px', width: '100%' }}>
                <tbody>
                    <tr>
                        {!authenticated ? <></> :
                            <td style={{ width: '50%', padding: '0px 4px' }}>
                                <button className='entityInfoTopButton' onClick={subscribeClick}>
                                    <img alt='subscribe' src={currentSubscribeStatus ? '/subscribe_fill.png' : '/subscribe_outline.png'} style={{ width: '24px', verticalAlign: 'middle', marginRight: '12px' }} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {currentSubscribeStatus ? 'Subscribed' : 'Subscribe'}
                                    </span>
                                </button>
                            </td>
                        }
                        <td style={{ width: '50%', padding: '0px 4px' }}>
                            {loadingPreviews ?
                                <button className='entityInfoTopButton'>
                                    <span className='glow' style={{ color: 'rgb(80, 80, 80)', fontSize: '16px' }}>Loading...</span>
                                </button>
                                :
                                <button className='entityInfoTopButton' onClick={playPreviewsClick}>
                                    <WebStoriesOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle', margin: '0px 3px 0px 12px' }}>
                                        Previews
                                    </span>
                                </button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    speakerSubscribeIds: state.user.speakerSubscribeIds,
})

const mapActionsToProps = {
    setNextPreviewsInfo,
    setSpeakerSubscribeStatus,
}

export default connect(mapStateToProps, mapActionsToProps)(SpeakerHeader);

