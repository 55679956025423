import { Apple } from "@material-ui/icons";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useNavigate } from "react-router-dom";

const HomeHeading = ({

}) => {
    const analytics = getAnalytics();
    const navigate = useNavigate();

    const appStoreHeaderClick = () => {
        logEvent(analytics, 'appstore_header_click');
    }

    const menuClick = () => {
        navigate('/menu');
    }

    const refreshPage = () => {
        window.location.reload(true);
    }

    return (
        <div style={{ lineHeight: 1.0, textAlign: 'right', borderSpacing: '0', padding: '16px 26px 16px', borderBottom: '0px solid rgb(224, 224, 224)' }}>
            <button onClick={refreshPage} style={{ float: 'left' }}>
                <img src='/icon-transparent.png' style={{ marginTop: '3px', verticalAlign: 'middle', height: '24px' }}/>
            </button>

            <div>
                <a href='https://apps.apple.com/us/app/podeux/id6477985096' target='_blank'>
                    <button className='homeHeaderButton' onClick={appStoreHeaderClick} style={{ verticalAlign: 'middle' }}>
                        <Apple style={{ verticalAlign: 'middle', fontSize: '18px', marginBottom: '2px' }} />
                        <span style={{ verticalAlign: 'middle', fontSize: '14px', paddingLeft: '3px' }}>
                            Get App
                        </span>
                    </button>
                </a>
                <button className='homeHeaderButton' onClick={menuClick} style={{ verticalAlign: 'middle' }}>
                    <div style={{ borderBottom: '1px solid black', borderTop: '1px solid black', width: '18px', height: '8px' }}>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default HomeHeading;
