
import { connect } from 'react-redux';
import { forwardRef, useEffect, useState } from 'react';

import { getAnalytics, logEvent } from "firebase/analytics";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import { IosShareRounded } from '@mui/icons-material';
import { Check, LinkRounded } from '@material-ui/icons';

import ButtonTipPopup from './ButtonTipPopup';
import CloseFooter from './CloseFooter';

import { speakerImageSrc } from './speakerHelper';
import { fancyTimeFormat } from './timeHelper';

import { LABEL_APPNAME, LABEL_DOMAIN } from '../constants/labels';
import { isIPhone } from './deviceHelper';
import { ACCENT_COLOR } from '../constants/colors';


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const ShareTrackDrawer = ({
    currentSection,
    close,
    open,
    track,
}) => {

    const analytics = getAnalytics();

    const [startAt, setStartAt] = useState(-1);

    const [currentMessage, setCurrentMessage] = useState(null);
    const [startFromSelection, setStartFromSelection] = useState(0);

    const [showCopied, setShowCopied] = useState(false);

    useEffect(() => {
        if (open) {
            setStartAt(-1);
            setShowCopied(false);
            setStartFromSelection(0);
            const currentTime = document.getElementById('audio').currentTime;
            for (let i = track.transcript.length - 1; i >= 0; i--) {
                const message = track.transcript[i];
                if (message.timeSeconds < currentTime) {
                    setCurrentMessage(message);
                    return;
                }
            }
        }
    }, [open])

    const startCurrentMessageClick = () => {
        setStartAt(Math.floor(currentMessage.timeSeconds));
        setStartFromSelection(2);
    }

    const startCurrentSectionClick = () => {
        const sectionStart = track.sectionInfos[currentSection].startTime
        setStartAt(Math.floor(sectionStart));
        setStartFromSelection(1);
    }

    const startFromBeginning = () => {
        setStartAt(-1);
        setStartFromSelection(0);
    }

    const closeCopied = () => {
        setShowCopied(false);
        close();
    }

    const onShareLinkClick = () => {
        let url = `https://${LABEL_DOMAIN}${track.pathname}`;
        if (startAt !== -1) {
            url += `?start=${startAt}s`;
        }
        logEvent(analytics, 'track_share_link');
        if (navigator.share && isIPhone()) {
            navigator.share({
                title: LABEL_APPNAME,
                text: url,
                url: url
            })
                .then(() => {
                    close();
                })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            navigator.clipboard.writeText(url);
            setShowCopied(true);
        }
    }

    const getMessageText = (m) => {
        const messageText = [];
        for (let i = 0; i < m.message.length; i++) {
            messageText.push(m.message[i].text)
        }
        return messageText.join('');
    }

    return (
        <div>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
                onClose={closeCopied}
            >
                <ButtonTipPopupRef close={closeCopied} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>
            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '20px 20px 0px 0px'
                    }
                }}
                anchor='bottom' open={open} onClose={close}>
                <Box
                    sx={{ width: 'auto' }}
                    role="presentation"
                >
                    <div className='horizontalCenter hasCloseFooter'>
                        {!track ? <></>
                            :
                            <div>
                                <div style={{ padding: '20px', borderRadius: '20px 20px 0px 0px', background: 'rgb(248, 248, 248', textAlign: 'center', marginBottom: '20px' }}>
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '20px' }}>
                                        Share Episode
                                    </div>
                                    <div style={{ color: 'rgb(120, 120, 120)', fontSize: '13px' }}>
                                        https://{LABEL_DOMAIN}/track/{track.fullTrackId ?? track.id}{startAt !== -1 ? <span style={{ color: 'black', fontWeight: '600' }}>?start={startAt}s</span> : ''}
                                    </div>
                                </div>
                                <div style={{ padding: '0px 16px 20px'}} >
                                    <div style={{ paddingBottom: '16px', fontSize: '18px', fontWeight: '500', textAlign: 'left' }}>
                                        Start from...
                                    </div>
                                    <div style={{ paddingBottom: '16px' }}>
                                        <button onClick={startFromBeginning} style={{ width: '100%', textAlign: 'left', padding: '12px 16px', border: '1px solid', borderColor: startFromSelection === 0 ? 'black' : 'rgb(224, 224, 224)', borderRadius: '8px' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '100%', paddingRight: '20px' }}>
                                                            <div style={{ fontWeight: startFromSelection === 0 ? '600' : '400', fontSize: '18px' }}>
                                                                <span style={{ verticalAlign: 'middle' }}>
                                                                    Beginning
                                                                </span>
                                                                {startFromSelection === 0 ?
                                                                    <Check style={{ color: ACCENT_COLOR, fontSize: '24px', marginLeft: '6px', verticalAlign: 'middle' }} />
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td style={{ fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                                            0:00
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </button>
                                    </div>
                                    {!currentSection ? <></> :
                                        <div style={{ paddingBottom: '16px' }}>
                                            <button onClick={startCurrentSectionClick} style={{ textAlign: 'left', width: '100%', padding: '12px 16px', border: '1px solid', borderColor: startFromSelection === 1 ? 'black' : 'rgb(224, 224, 224)', borderRadius: '8px' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '100%', paddingRight: '20px' }}>
                                                                <div style={{ fontWeight: startFromSelection === 1 ? '600' : '400', fontSize: '18px' }}>
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                        Current Section
                                                                    </span>
                                                                    {startFromSelection === 1 ?
                                                                        <Check style={{ color: ACCENT_COLOR, fontSize: '24px', marginLeft: '6px', verticalAlign: 'middle' }} />
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td style={{ fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                                                {fancyTimeFormat(track.sectionInfos[currentSection].startTime)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div style={{ marginTop: '6px' }}>
                                                    <div style={{ fontSize: '14px', fontWeight: '400' }}>
                                                        <span className='messageTextPreview'>
                                                            {track.sectionInfos[currentSection].title}
                                                        </span>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    }

                                    {!currentMessage ? <></> :
                                        <div style={{ paddingBottom: '30px' }}>
                                            <button onClick={startCurrentMessageClick} style={{ width: '100%', textAlign: 'left', padding: '12px 16px', border: '1px solid', borderColor: startFromSelection === 2 ? 'black' : 'rgb(224, 224, 224)', borderRadius: '8px' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '100%', paddingRight: '20px' }}>
                                                                <div style={{ fontWeight: startFromSelection === 2 ? '600' : '400', fontSize: '18px' }}>
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                        Current Message
                                                                    </span>
                                                                    {startFromSelection === 2 ?
                                                                        <Check style={{ color: ACCENT_COLOR, fontSize: '24px', marginLeft: '6px', verticalAlign: 'middle' }} />
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td style={{ fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                                                {fancyTimeFormat(currentMessage.timeSeconds)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div style={{ marginTop: '6px' }}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ minWidth: '54px' }}>
                                                                    <img src={speakerImageSrc(currentMessage.speakerId, track.speakers)} style={{ verticalAlign: 'middle', width: '44px', borderRadius: '50%' }} />
                                                                </td>
                                                                <td>
                                                                    <span className='messageTextPreview'>{getMessageText(currentMessage)}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </button>
                                        </div>
                                    }

                                    <div>
                                        <button onClick={onShareLinkClick} style={{
                                            width: '100%',
                                            padding: '10px',
                                            background: 'black',
                                            color: 'white',
                                            borderRadius: '8px',
                                            fontSize: '18px',
                                            fontWeight: '600'
                                        }}>
                                            <IosShareRounded style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                                            <span style={{ verticalAlign: 'middle' }}>
                                                Share
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        <CloseFooter onClick={close} />
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(ShareTrackDrawer);
