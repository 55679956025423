import { forwardRef, useState } from "react";

import { getAnalytics } from "firebase/analytics";
import ButtonTipPopup from "./ButtonTipPopup";

import Modal from '@mui/material/Modal';

import { LinkRounded } from "@mui/icons-material";
import { eventLog } from "./eventHelper";


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const TopicSpeakerPreviews = ({
    hideTopic,
    playTopic,
    topic,
    width,
    overrideSpeakersPerRow,
    showAll,
}) => {
    const profilePicMarginRight = 20;
    const speakersPerRow = overrideSpeakersPerRow ? overrideSpeakersPerRow : width > 400 ? 4 : 3;
    const maxSpeakers = showAll ? 1000 : speakersPerRow;
    const buffer = (speakersPerRow - 1) * (profilePicMarginRight + 1) / speakersPerRow;

    const analytics = getAnalytics();

    const [showCopied, setShowCopied] = useState(false);
    const toggleShowCopied = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowCopied(open);
    }

    const playTopicClick = (topic, previewId) => () => {
        let preview = null;
        for (let i = 0; i < topic.previews.length; i++) {
            if (topic.previews[i].id === previewId) {
                preview = topic.previews[i];
                break;
            }
        }

        if (preview !== null) {
            eventLog(12, {
                'previewId': preview.id,
                'speakerId': preview.person.id,
                'topicId': topic.id,
            })
        }
        playTopic(topic, previewId)();
    }

    const uniqueSpeakerPreviews = (previews) => {
        const speakerIds = new Set();
        const speakerPreviews = [];
        for (let i = 0; i < previews.length; i++) {
            const preview = previews[i];
            const previewSpeakerId = preview.person.id;
            if (!speakerIds.has(previewSpeakerId)) {
                speakerIds.add(previewSpeakerId)
                speakerPreviews.push(preview);
            }
        }
        return speakerPreviews;
    }

    return (
        <div>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
            >
                <ButtonTipPopupRef close={toggleShowCopied(false)} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>

            {!!hideTopic ?
                <></>
                :
                <table style={{ margin: '20px 0px 20px' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '100%' }}>
                                <div style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                                    <div className='subheading'>Trending speakers on "{topic.id}"</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            }
            {
                uniqueSpeakerPreviews(topic.previews).slice(0, maxSpeakers).map((preview, speakerPreviewIndex) => (
                    <button key={speakerPreviewIndex} onClick={playTopicClick(topic, preview.id)} style={{ verticalAlign: 'top', marginBottom: '16px', marginRight: (speakerPreviewIndex + 1) % speakersPerRow === 0 ? '0px' : `${profilePicMarginRight}px` }}>
                        <div>
                            <img alt='profile' src={preview.person.imageURL} style={{ minWidth: `${width / speakersPerRow - buffer}px`, minHeight: `${width / speakersPerRow - buffer}px`, width: `${width / speakersPerRow - buffer}px`, border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                            <div className='profilePictureName' style={{ maxWidth: `${width / speakersPerRow - buffer}px` }}>
                                {preview.person.name}
                            </div>
                        </div>
                    </button>
                ))
            }
        </div>
    );
};

export default TopicSpeakerPreviews;