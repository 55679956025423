// Redux.
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
    loadForMePreviews,
} from "../redux/actions/userActions";
import {
    setNextPreviewsInfo,
} from "../redux/actions/dataActions";
import { useNavigate } from "react-router-dom";
import { loadTrack } from "./audioHelper";
import { eventPreviewView, eventLog } from "./eventHelper";
import ClipLargePreviewLoading from "./ClipLargePreviewLoading";
import { NavigateBefore, NavigateNext, TuneRounded } from "@material-ui/icons";
import ClipOrPreviewInfo from "./ClipOrPreviewInfo";
import { RESPONSIVE_LARGE_WIDTH } from "../constants/sizes";
import useWindowDimensions from "./windowHelper";
import GetStartedCard from "./GetStartedCard";


const ForMePreview = ({
    // Redux.
    authenticated,
    forMePreviewsData,
    forMeLoadingCount,
    getStartedTopicsStatus,
    loadForMePreviews,
    setNextPreviewsInfo,
}) => {

    const [selectedTopic, setSelectedTopic] = useState(null);
    const [previews, setPreviews] = useState([]);
    const [previewIndex, setPreviewIndex] = useState(0);
    const { height, width } = useWindowDimensions();

    const forMePreviewScrollContainerId = 'forMePreviewScrollContainer';

    useEffect(() => {
        if (authenticated && !forMePreviewsData) {
            loadForMePreviews();
        }
    }, [authenticated]);

    useEffect(() => {
        if (previewIndex < previews.length) {
            let previewId = previews[previewIndex].id
            eventPreviewView(previewId, 0);
        }
    }, [previewIndex, previews])

    useEffect(() => {
        if (!forMePreviewsData) {
            setPreviews([]);
        } else {
            setPreviews(forMePreviewsData.previews);
        }
    }, [forMePreviewsData])

    const navigate = useNavigate();

    const selectTopic = (topic) => () => {
        if (topic) {
            if (selectedTopic !== topic.id) {
                eventLog(7, {
                    'topicId': topic.id,
                });
                setSelectedTopic(topic.id);
                const container = document.getElementById(forMePreviewScrollContainerId);
                container.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
                setPreviewIndex(0);
                setPreviews(topic.previews);
            }
        } else {
            if (selectedTopic !== null) {
                setSelectedTopic(null);
                const container = document.getElementById(forMePreviewScrollContainerId);
                container.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
                setPreviewIndex(0);
                setPreviews(forMePreviewsData.previews);
            }
        }

    }

    const playPreview = (previewId) => () => {
        let index = 0;
        for (let i = 0; i < previews.length; i++) {
            if (previewId === previews[i].id) {
                index = i;
                break;
            }
        }
        const preview = previews[index];
        loadTrack(preview.mp3URL, 0)
        setNextPreviewsInfo([preview]);
        navigate('/previews', { state: { closeAllModal: true } });
    }

    const scrollToPreviewId = (previewId) => () => {
        const elem = document.getElementById(previewId);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const prevPreview = () => {
        const newIndex = Math.max(0, previewIndex - 1);
        const elem = document.getElementById(previews[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const nextPreview = () => {
        const newIndex = Math.min(previews.length - 1, previewIndex + 1);
        const elem = document.getElementById(previews[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const settingsClick = () => {
        navigate('/settings');
    }

    const getStartedClick = () => {
        navigate('/get_started');
    }


    return (
        <div>
            {width < RESPONSIVE_LARGE_WIDTH ? <></> :
                <div style={{ marginBottom: '5px', marginLeft: '20px', marginRight: '20px' }}>
                    <button onClick={settingsClick} className='settingsButton'>
                        <TuneRounded style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                    </button>
                    <button onClick={selectTopic(null)} className={selectedTopic === null ? 'selectedTopic' : 'unselectedTopic'} style={{ margin: '0px 8px 8px 0px' }}>
                        for me
                    </button>
                    {forMePreviewsData.topics.map((topic, topicIndex) => (
                        <button key={topic.id} onClick={selectTopic(topic)} className={selectedTopic === topic.id ? 'selectedTopic' : 'unselectedTopic'} style={{ margin: '0px 8px 8px 0px' }}>
                            {topic.id}
                        </button>
                    ))}
                </div>
            }

            {forMeLoadingCount > 0 ?
                <div>
                    <ClipLargePreviewLoading count={2} />
                </div>
                :
                previews.length < 1 ? <></> :
                    <div style={{ position: 'relative' }}>
                        <div id={forMePreviewScrollContainerId} style={{ margin: width >= RESPONSIVE_LARGE_WIDTH ? '20px 74px' : '0px 0px', scrollPadding: '20px' }} className='hslider-container hideScrollbar horizontalScroll'>
                            {getStartedTopicsStatus === false ?
                                <span className='hslider-children' style={{ lineHeight: 0, paddingLeft: '20px', paddingRight: '10px' }}>
                                    <button onClick={getStartedClick}>
                                        <GetStartedCard />
                                    </button>
                                </span>
                                :
                                <span className='hslider-children' style={{ paddingLeft: '20px' }}></span>
                            }
                            {previews.map((preview, previewIndex) => (
                                <span key={previewIndex} className='hslider-children' style={{ paddingRight: previewIndex === previews.length - 1 ? '20px' : '10px' }}>
                                    <ClipOrPreviewInfo clipOrPreview={preview} playClipOrPreview={playPreview(preview.id)} index={previewIndex} indexInViewport={(x) => { setPreviewIndex(x) }} />
                                </span>
                            ))}
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'left', paddingLeft: '10px', position: 'absolute', top: '50%', left: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={prevPreview} className={'nextPrevButtons'} style={{ opacity: previewIndex === 0 ? 0.3 : 1 }}>
                                        <NavigateBefore style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'right', paddingRight: '10px', position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={nextPreview} className={'nextPrevButtons'} style={{ opacity: previewIndex + 1 === previews.length ? 0.3 : 1 }}>
                                        <NavigateNext style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                        </div>
                    </div>
            }

            {width >= RESPONSIVE_LARGE_WIDTH ?
                <></>
                :
                <div style={{ marginTop: '16px' }} className='hideScrollbar horizontalScroll'>
                    <span style={{ marginLeft: '20px' }}>
                        <button onClick={settingsClick} className='settingsButton'>
                            <TuneRounded style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                        </button>
                    </span>

                    {forMeLoadingCount > 0 ? <></> :
                        <span>
                            <button onClick={selectTopic(null)} className={selectedTopic === null ? 'selectedTopic' : 'unselectedTopic'} style={{ marginRight: '8px' }}>
                                for me
                            </button>
                            {forMePreviewsData.topics.map((topic, topicIndex) => (
                                <button key={topic.id} onClick={selectTopic(topic)} className={selectedTopic === topic.id ? 'selectedTopic' : 'unselectedTopic'} style={{ marginRight: topicIndex === forMePreviewsData.topics.length - 1 ? '20px' : '8px' }}>
                                    {topic.id}
                                </button>
                            ))}
                        </span>
                    }

                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    forMePreviewsData: state.user.forMePreviewsData,
    forMeLoadingCount: state.user.forMeLoadingCount,
    getStartedTopicsStatus: state.user.getStartedTopicsStatus,
})

const mapActionsToProps = {
    loadForMePreviews,
    setNextPreviewsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(ForMePreview);