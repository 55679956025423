import { ArrowCircleRight } from "@mui/icons-material";
import { ACCENT_COLOR } from "../constants/colors";
import useWindowDimensions from "./windowHelper";
import { PREVIEW_CARD_MAX_WIDTH, PREVIEW_CARD_HEIGHT, PREVIEW_NEXT_CLIP_BUFFER } from "../constants/sizes";
import { useEffect } from "react";
import { TIP_GET_STARTED } from "../constants/local_storage_constants";
import { useNavigate } from "react-router-dom";

const GetStartedCard = () => {
    const { _, width } = useWindowDimensions();

    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.TIP_GET_STARTED) {
            localStorage.setItem(TIP_GET_STARTED, true)
            navigate("/get_started");
        }
    }, []);

    return (
      <div style={{
         width: `${Math.min(width, PREVIEW_CARD_MAX_WIDTH) - PREVIEW_NEXT_CLIP_BUFFER}px`,
         height: `${PREVIEW_CARD_HEIGHT}px`,
         background: `${ACCENT_COLOR}28`,
         borderRadius: '10px',
      }}>
        <div style={{ fontSize: '48px', textAlign: 'left', fontWeight: '700', padding: '60px 20px' }}>
            <div>
                Discover
            </div>
            <div>
                New
            </div>
            <div>
                Podcasts
            </div>
        </div>
        <span className='animate__animated animate__zoomIn animate__delay-1s' style={{ background: 'rgb(30, 30, 30)', borderRadius: '30px', display: 'inline-block', padding: '12px 20px' }}>
            <ArrowCircleRight style={{ verticalAlign: 'middle', color: 'rgb(251, 250, 252)', fontSize: '24px' }} />
            <span style={{ verticalAlign: 'middle', color: 'rgb(251, 250, 252)', fontSize: '18px', fontWeight: '500', margin: '0px 8px'}}>
                Start Here
            </span>
        </span>
      </div>
    );
  };
  
  export default GetStartedCard;
  