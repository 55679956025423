import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from "react-redux";
import {
    setNextPreviewsInfo,
} from '../redux/actions/dataActions';

// Components
import { loadTrack } from './audioHelper';
import ClipLargePreviewLoading from './ClipLargePreviewLoading';
import ClipOrPreviewInfo from './ClipOrPreviewInfo';
import { RESPONSIVE_LARGE_WIDTH } from '../constants/sizes';
import useWindowDimensions from './windowHelper';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { shuffle } from 'lodash';


const FirstUserPreviews = ({
    setNextPreviewsInfo,
}) => {
    const [loading, setLoading] = useState(false);
    const [previews, setPreviews] = useState([]);
    const [previewIndex, setPreviewIndex] = useState(0);

    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        setLoading(true);
        axios
            .get('/first_user_previews')
            .then((res) => {
                setLoading(false);
                let shuffledPreviews = shuffle(res.data.previews);
                setPreviews(shuffledPreviews);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const scrollToPreviewId = (previewId) => () => {
        const elem = document.getElementById(previewId);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const prevPreview = () => {
        const newIndex = Math.max(0, previewIndex - 1);
        const elem = document.getElementById(previews[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const nextPreview = () => {
        const newIndex = Math.min(previews.length - 1, previewIndex + 1);
        const elem = document.getElementById(previews[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const playPreview = (previewId) => () => {
        let preview = null
        for (let i = 0; i < previews.length; i++) {
            if (previewId === previews[i].id) {
                preview = previews[i];
                break;
            }
        }
        loadTrack(preview.mp3URL, 0)
        setNextPreviewsInfo([preview]);
        navigate('/previews', { state: { closeAllModal: true } });
    }

    const previewsScrollContainerId = 'firstUserPreviewsScrollContainer';

    return (
        <div>
            {loading ?
                <div style={{ margin: width >= RESPONSIVE_LARGE_WIDTH ? '0px 40px' : '0px 0px' }}>
                    <ClipLargePreviewLoading count={1} />
                </div>
                :
                <div>
                    {!previews ?
                        <div style={{ padding: '20px' }}>Unexpected error, please try again later.</div>
                        :
                        <div style={{ position: 'relative' }}>
                            <div id={previewsScrollContainerId} style={{ margin: width >= RESPONSIVE_LARGE_WIDTH ? '0px 54px' : '0px 0px', scrollPadding: '20px' }} className='hslider-container hideScrollbar horizontalScroll'>
                                {previews.map((preview, previewIndex) => (
                                    <span key={previewIndex} className='hslider-children' style={{ paddingRight: previewIndex === previews.length - 1 ? '20px' : '10px' }}>
                                        <ClipOrPreviewInfo clipOrPreview={preview} playClipOrPreview={playPreview(preview.id)} index={previewIndex} indexInViewport={(x) => { setPreviewIndex(x) }} />
                                    </span>
                                ))}
                            </div>
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'left', position: 'absolute', top: '50%', left: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={prevPreview} className={'nextPrevButtons'} style={{ opacity: previewIndex === 0 ? 0.3 : 1 }}>
                                        <NavigateBefore style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                            {width >= RESPONSIVE_LARGE_WIDTH ?
                                <div style={{ float: 'right', position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)' }}>
                                    <button onClick={nextPreview} className={'nextPrevButtons'} style={{ opacity: previewIndex + 1 === previews.length ? 0.3 : 1 }}>
                                        <NavigateNext style={{ color: 'rgb(30, 30, 30)', fontSize: '36px', verticalAlign: 'middle' }} />
                                    </button>
                                </div>
                                :
                                <></>}
                        </div>
                    }
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
    setNextPreviewsInfo,
};

export default connect(mapStateToProps, mapActionsToProps)(FirstUserPreviews);