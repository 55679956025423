import update from 'immutability-helper';

import {
  ADD_SAVED_TRACK_IDS,
  ADD_SPEAKER_SUBSCRIBE_IDS,
  FOR_ME_LOADING_COUNT_DECREASE,
  FOR_ME_LOADING_COUNT_INCREASE,
  LOAD_LATEST_SUBSCRIED,
  LOAD_RECENTLY_PLAYED,
  LOAD_RECENTLY_SAVED,
  SET_AUTHENTICATED,
  SET_FOR_ME,
  SET_GET_STARTED_TOPICS_STATUS_TRUE,
  SET_LATEST_SUBSCRIED,
  SET_PODCAST_SUBSCRIBE_STATUS,
  SET_RECENTLY_PLAYED,
  SET_RECENTLY_SAVED,
  SET_SAVE_TRACK_STATUS,
  SET_SPEAKER_SUBSCRIBE_STATUS,
  SET_UNAUTHENTICATED,
  SET_USER,
} from '../types';

const initialState = {
  authenticated: null,
  forMeLoadingCount: 0,
  forMePreviewsData: null,
  getStartedTopicsStatus: null,
  latestSubscribedData: null,
  latestSubscribedLoading: false,
  phoneNumber: '',
  podcastSubscribeIds: [],
  recentlyPlayedData: null,
  recentlyPlayedLoading: false,
  recentlySavedData: null,
  recentlySavedLoading: false,
  savedTrackIds: [],
  speakerSubscribeIds: [],
};


const updateSavedTrackIds = (savedTrackIds, trackId, saveStatus) => {
  const newSavedTrackIds = !savedTrackIds ? [] : savedTrackIds.filter((savedTrackId) => {
    return savedTrackId !== trackId
  })
  if (saveStatus) {
    newSavedTrackIds.push(trackId);
  }
  return newSavedTrackIds;
};


const updateSubscribeIds = (subscribeIds, subscribeId, subscribeStatus) => {
  const newSubscribeIds = !subscribeIds ? [] : subscribeIds.filter((savedSubscribeId) => {
    return savedSubscribeId !== subscribeId
  })
  if (subscribeStatus) {
    newSubscribeIds.push(subscribeId);
  }
  return newSubscribeIds;
};


const addSavedTrackIds = (savedTrackIds, newTrackIds) => {
  return [...new Set([...savedTrackIds, ...newTrackIds])]
};


const addSpeakerSubscribeIds = (speakerSubscribeIds, newSpeakerSubscribeIds) => {
  return [...new Set([...speakerSubscribeIds, ...newSpeakerSubscribeIds])]
};


export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SAVED_TRACK_IDS:
      return {
        ...state,
        savedTrackIds: addSavedTrackIds(state.savedTrackIds, action.payload.trackIds),
      };
    case ADD_SPEAKER_SUBSCRIBE_IDS:
      return {
        ...state,
        speakerSubscribeIds: addSpeakerSubscribeIds(state.speakerSubscribeIds, action.payload.speakerIds),
      };
    case FOR_ME_LOADING_COUNT_DECREASE:
      return {
        ...state,
        forMeLoadingCount: state.forMeLoadingCount - 1,
      };
    case FOR_ME_LOADING_COUNT_INCREASE:
      return {
        ...state,
        forMeLoadingCount: state.forMeLoadingCount + 1,
      };
    case LOAD_LATEST_SUBSCRIED:
      return {
        ...state,
        latestSubscribedLoading: true,
      };
    case LOAD_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayedLoading: true,
      };
    case LOAD_RECENTLY_SAVED:
      return {
        ...state,
        recentlySavedLoading: true,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_FOR_ME:
      const forMePreviewsData = action.payload;
      if (forMePreviewsData) {
        forMePreviewsData.setTime = Date.now();
      }
      return {
        ...state,
        forMePreviewsData: forMePreviewsData,
      };
    case SET_GET_STARTED_TOPICS_STATUS_TRUE:
      return {
        ...state,
        getStartedTopicsStatus: true,
      };
    case SET_LATEST_SUBSCRIED:
      const latestSubscribedData = action.payload;
      if (latestSubscribedData) {
        // TODO move this to server side.
        latestSubscribedData.setTime = Date.now();
      }
      return {
        ...state,
        latestSubscribedData: latestSubscribedData,
        latestSubscribedLoading: false,
      };
    case SET_PODCAST_SUBSCRIBE_STATUS:
      return {
        ...state,
        podcastSubscribeIds: updateSubscribeIds(state.podcastSubscribeIds, action.payload.subscribeId, action.payload.subscribeStatus),
      };
    case SET_RECENTLY_PLAYED:
      return {
        ...state,
        recentlyPlayedData: action.payload,
        recentlyPlayedLoading: false,
      };
    case SET_RECENTLY_SAVED:
      return {
        ...state,
        recentlySavedData: action.payload,
        recentlySavedLoading: false,
        savedTrackIds: addSavedTrackIds(state.savedTrackIds, action.payload ? action.payload.trackInfos.map((x) => x.id) : []),
      };
    case SET_SPEAKER_SUBSCRIBE_STATUS:
      return {
        ...state,
        speakerSubscribeIds: updateSubscribeIds(state.speakerSubscribeIds, action.payload.subscribeId, action.payload.subscribeStatus),
      };
    case SET_UNAUTHENTICATED:
      return {
        ...initialState,
        authenticated: false
      };
    case SET_USER:
      return {
        ...state,
        ...action.payload
      };
    case SET_SAVE_TRACK_STATUS:
      return {
        ...state,
        savedTrackIds: updateSavedTrackIds(state.savedTrackIds, action.payload.trackId, action.payload.saveStatus),
      }
    default:
      return state;
  }
}