import axios from 'axios';

import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';
import {
    setNextPreviewsInfo,
    setTrack,
} from '../redux/actions/dataActions'

// Components.
import CloseFooter from './CloseFooter';
import EntityInfoDrawer from './EntityInfoDrawer';
import SyncTranscriptAudio from './SyncTranscriptAudio';
import Transcript from './Transcript';
import PlayPauseBar from './PlayPauseBar';
import PreviewActions from './PreviewActions';
import ProfilePicture from './ProfilePicture';

import { eventPreviewView } from './eventHelper';
import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";

import { RESPONSIVE_SMALL_HEIGHT } from '../constants/sizes';
import { PLAYBACK_RATE } from '../constants/local_storage_constants';

import { ACCENT_COLOR, LOADING_COLOR, speakerColor } from '../constants/colors';

import { Refresh, Search } from '@material-ui/icons';
import { Drawer } from '@mui/material';
import { AirlineStops } from '@mui/icons-material';
import { shuffle } from 'lodash';
import { useNavigate } from 'react-router-dom';


const PreviewCard = ({
    preview,
    previewIndexInViewport,
    currentTime,
    finishedPreviewIds,
    index,
    nextPreview,
    playPreview,
    playFullEpisode,
    playing,
    shouldLoadTrack,
    showAudioPlayerDrawer,

    // Redux state.
    authenticated,
    nextPreviewsInfo,
    track,

    // Redux actions.
    setNextPreviewsInfo,
    setTrack,
}) => {
    const previewCardViewport = useRef();
    const currentViewport = useRef();
    const transcriptContainerRef = useRef();

    const audio = document.getElementById('audio');

    const isCurrentViewport = useIsInViewport(currentViewport);
    const [localPreview, setLocalPreview] = useState(null);
    const [missingPreview, setMissingPreview] = useState(false);
    const [scrollToCurrentMessage, setScrollToCurrentMessage] = useState(true);

    const contentRef = useRef(null)
    const previewSourceBottomRef = useRef(null);
    const previewViewSeconds = useRef(new Set())

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (scrollToCurrentMessage && audio.paused) {
            if (!finishedPreviewIds.current.has(preview.id)) {
                setTimeout(() => {
                    audio.play().catch(function () { });
                }, 1000);
            }
        }
    }, [scrollToCurrentMessage])

    useEffect(() => {
        if (!!localPreview && nextPreviewsInfo && (index === nextPreviewsInfo.previewsInfo.length - 1) && (nextPreviewsInfo.previewsInfo.length < 50)) {
            const addedPreviewIds = new Set();
            for (let i = 0; i < nextPreviewsInfo.previewsInfo.length; i++) {
                addedPreviewIds.add(nextPreviewsInfo.previewsInfo[i].id);
            }
            const nextPreviews = [];
            if (localPreview.relatedTopicPreviews) {
                for (let i = 0; i < localPreview.relatedTopicPreviews.length; i++) {
                    const previews = localPreview.relatedTopicPreviews[i].previews;
                    for (let j = 0; j < previews.length; j++) {
                        const p = previews[j];
                        if (!addedPreviewIds.has(p.id)) {
                            nextPreviews.push(p);
                            addedPreviewIds.add(p.id);
                        }
                    }
                }
            }
            const shuffledNextPreviews = shuffle(nextPreviews);
            setNextPreviewsInfo(nextPreviewsInfo.previewsInfo.concat(shuffledNextPreviews));
        }
    }, [localPreview])

    const [reloadTranscript, setReloadTranscript] = useState(false);

    useEffect(() => {
        if (!isCurrentViewport) {
            // Reload transcript so when user goes back, the transcript starts at the top.
            setReloadTranscript(true);
            setTimeout(() => {
                setReloadTranscript(false);
            }, 100);
        } else {
            previewIndexInViewport(index);
            if (!finishedPreviewIds.current.has(preview.id)) {
                playPreview(preview);
            }

            const pathName = `/preview/${preview.fullTrackId}/${preview.messageIndex}`;
            window.history.replaceState(null, '', pathName);
        }
    }, [isCurrentViewport]);

    const scrollToPreviewCard = () => {
        const previewCardElem = document.getElementById(preview.id);
        if (previewCardElem) {
            previewCardElem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    useEffect(() => {
        const pathName = `/preview/${preview.fullTrackId}/${preview.messageIndex}`;
        if (shouldLoadTrack && !localPreview) {
            if (track && track.pathname === pathName) {
                setLocalPreview(track);
            } else {
                axios
                    .get(pathName)
                    .then((res) => {
                        setLocalPreview(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMissingPreview(true);
                    });
            }
        }
    }, [shouldLoadTrack]);

    useEffect(() => {
        if (!playing && isCurrentViewport && audio && audio.currentTime > 1) {
            setScrollToCurrentMessage(false);
        }
    }, [playing])

    useEffect(() => {
        if (playing && localPreview && audio.src === localPreview.mp3URL && isCurrentViewport) {
            const floorTimeSecs = Math.floor(currentTime);
            if (floorTimeSecs % 10 === 0) {
                if (!previewViewSeconds.current.has(floorTimeSecs)) {
                    previewViewSeconds.current.add(floorTimeSecs)
                    eventPreviewView(localPreview.id, floorTimeSecs);
                }
            }
        }
    }, [currentTime, localPreview, playing])

    useEffect(() => {
        if (isCurrentViewport && localPreview && !showAudioPlayerDrawer) {
            setTrack(localPreview);
            setScrollToCurrentMessage(true);
        }
    }, [isCurrentViewport, localPreview, showAudioPlayerDrawer])

    const checkAudioSource = () => {
        if (audio.src !== preview.mp3URL) {
            if (!finishedPreviewIds.current.has(preview.id)) {
                playPreview(preview);
            } else {
                audio.pause();
                audio.src = preview.mp3URL;
            }
        }
        if (localPreview) {
            setTrack(localPreview);
        }
    }

    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const selectedSpeakerId = useRef('');

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPersonInfo(open);
    }

    const openSpeakerInfo = (speakerId) => () => {
        selectedSpeakerId.current = speakerId;
        setTimeout(() => {
            setOpenPersonInfo(true);
        }, 100);
    }

    const navigate = useNavigate();
    const navToSearch = (topic) => () => {
        navigate(`/search/${encodeURIComponent(topic)}` )
    }

    useEffect(() => {
        if (isCurrentViewport && !showAudioPlayerDrawer) {
            if (openPersonInfo) {
                audio.pause();
            } else {
                checkAudioSource();
                if (!finishedPreviewIds.current.has(preview.id)) {
                    audio.play().catch(function () { });
                }
            }
        }
    }, [openPersonInfo, isCurrentViewport, showAudioPlayerDrawer])

    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab"));

    const previewTitleClassName = height > RESPONSIVE_SMALL_HEIGHT ? 'previewTitleLarge' : 'previewTitle'
    const previewTop = (
        <div className='previewTopInfo' style={{ zIndex: '99' }}>
            <div>
                <div className={previewTitleClassName} style={{ marginBottom: '10px' }} >
                    {preview.title}
                </div>
                <button onClick={openSpeakerInfo(preview.person.id)}>
                    <table style={{ borderSpacing: '0px' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ paddingRight: '13px', verticalAlign: 'middle' }}>
                                        <ProfilePicture
                                            borderSize={2}
                                            imageSrc={preview.person.imageURL}
                                            color={!track ? LOADING_COLOR : speakerColor(preview.person.id, track.speakers)}
                                            profileClick={null}
                                            size={'60px'}
                                        />
                                    </div>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <div>
                                        <div className='previewSpeakerName'>
                                            {preview.person.name}
                                        </div>
                                        <div className='previewSpeakerDescription'>
                                            {preview.person.description}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                {!localPreview ? <></> :
                    <div className='previewCardTopics'>
                        {localPreview.relatedTopicPreviews.slice(0, 2).map((relaticTopicPreviews, index) => (
                            <button key={relaticTopicPreviews.id} className='topicInPreviewCard' onClick={navToSearch(relaticTopicPreviews.id)}>
                                <span>
                                    <Search style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                                </span>
                                <span style={{ verticalAlign: 'middle', paddingLeft: '4px' }}>
                                {relaticTopicPreviews.id}
                                </span>
                                
                            </button>
                        ))}
                    </div>
                }
            </div>
        </div>
    );

    const [showPreviewActions, setShowPreviewActions] = useState(false);
    const togglePreviewActions = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            audio.pause();
        } else {
            audio.play().catch(function () {
                // Browser doesn't allow play until user click.
            });
        }
        setShowPreviewActions(open);
    };
    const onSpeedClick = (val) => () => {
        audio.playbackRate = val;
        localStorage.setItem(PLAYBACK_RATE, val);
        togglePreviewActions(false)();
    }

    const continueListeningFinishedPreview = () => {
        playFullEpisode(localPreview.previewStartTime + localPreview.totalTimeSeconds);
        finishedPreviewIds.current.delete(preview.id);
    }

    const replayFinishedPreview = () => {
        audio.play();
        finishedPreviewIds.current.delete(preview.id);
    }

    return (
        <div id={preview.id} ref={previewCardViewport} className='preview-slider hideScrollbar'
            style={{
                opacity: isCurrentViewport ? 1.0 : 0.6, width: `${Math.min(width, 540) - 40}px`,
                height: `${height - 84 - safeAreaBottom}px`,
            }}>
            <div className='hideScrollbar' style={{ height: '100%' }}>
                <EntityInfoDrawer
                    open={openPersonInfo}
                    onClose={togglePersonInfo(false)}
                    speakerId={selectedSpeakerId.current} />
                <Drawer
                    PaperProps={{
                        sx: {
                            borderRadius: '10px 10px 0px 0px'
                        }
                    }}
                    anchor='bottom' open={showPreviewActions} onClose={togglePreviewActions(false)}>
                    <div className='horizontalCenter hasCloseFooter'>
                        <div style={{ padding: '10px 0px 20px' }}>
                            <PreviewActions
                                preview={preview}
                                onSpeedClick={onSpeedClick}
                            />
                            <CloseFooter onClick={togglePreviewActions(false)} />
                        </div>
                    </div>
                </Drawer>

                <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center', height: '100%' }} className='hideScrollbar'>
                    <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
                </div>
                {missingPreview ?
                    <div className='heading center' style={{ paddingBottom: '20px', minWidth: '280px', textAlign: 'center' }} >
                        <div>
                            No longer available
                        </div>
                        <div style={{ padding: '20px' }}>
                            <button onClick={nextPreview} style={{ paddingLeft: '14px', fontWeight: '600', fontSize: '20px', color: ACCENT_COLOR }}>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Next Preview
                                </span>
                            </button>
                        </div>
                    </div>
                    :
                    <div ref={contentRef} className='hideScrollbar' style={{ height: '100%' }}>
                        {previewTop}
                        {!localPreview || reloadTranscript || showAudioPlayerDrawer ?
                            <div className={isCurrentViewport ? 'glow' : ''}>
                                <div style={{ padding: '16px', overflowY: 'auto' }}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='loading' style={{ marginRight: '10px', height: '44px', width: '44px', borderRadius: '50%' }} />
                                                </td>
                                                <td style={{ width: '100%' }}>
                                                    <div className='loading' style={{ borderRadius: '6px', height: '44px', width: '160px' }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                    <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                    <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                    <div className='loading' style={{ margin: '8px 0px 0px', borderRadius: '8px', height: '70px', width: '100%' }} />
                                </div>
                            </div>
                            :
                            <div className='hideScrollbar' style={{ height: '100%' }}>
                                {finishedPreviewIds.current.has(localPreview.id) ?
                                    <div style={{ paddingTop: `${previewCardViewport.current.clientHeight / 2 - 200}px` }}>
                                        <div style={{ padding: '20px', textAlign: 'center' }} >
                                            <button onClick={continueListeningFinishedPreview}
                                                style={{ padding: '13px 30px', textAlign: 'center', background: 'black', color: 'white', borderRadius: '40px' }}>
                                                <AirlineStops style={{ verticalAlign: 'middle', fontSize: '22px' }} />
                                                <span style={{ verticalAlign: 'middle', fontSize: '16px', fontWeight: '500', marginLeft: '6px' }} >Continue Listening</span>
                                            </button>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <button onClick={replayFinishedPreview}>
                                                <Refresh style={{ verticalAlign: 'middle', fontSize: '18px' }} />
                                                <span style={{ verticalAlign: 'middle', fontSize: '15px', marginLeft: '6px' }} >Replay</span>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className='hideScrollbar' style={{ height: '100%' }}>
                                        <div style={{ overflowY: 'auto', height: '100%' }} className='hideScrollbar'>
                                            <div ref={transcriptContainerRef}>
                                                <Transcript
                                                    pathname={localPreview.pathname}
                                                    autoscrollPaddingBottom={previewSourceBottomRef.current ?
                                                        previewSourceBottomRef.current.clientHeight + (height < RESPONSIVE_SMALL_HEIGHT ? 40 : 40) : 80}
                                                    curTime={isCurrentViewport ? currentTime : 0}
                                                    currentSection={0}
                                                    isClipOrPreview={true}
                                                    isCurrentViewport={isCurrentViewport}
                                                    openSpeakerInfo={openSpeakerInfo}
                                                    playing={playing}
                                                    scrollToCurrentMessage={scrollToCurrentMessage}
                                                    setScrollToCurrentMessage={setScrollToCurrentMessage}
                                                    setCurrentSpeaker={(currentSpeaker) => {
                                                        // unused current speacker
                                                    }}
                                                    // scrollToTopTopPadding={previewTopRef.current.clientHeight + 10}
                                                    showSpeakerDetails={false}
                                                    transcriptContainerRef={previewCardViewport}
                                                    topContent={<></>}
                                                    track={localPreview}
                                                />
                                            </div>
                                        </div>


                                        <SyncTranscriptAudio
                                            bottom={previewSourceBottomRef.current ? `${previewSourceBottomRef.current.clientHeight + 10}px` : '0px'}
                                            currentTime={currentTime}
                                            scrollToCurrentMessage={scrollToCurrentMessage}
                                            setScrollToCurrentMessage={setScrollToCurrentMessage}
                                            track={localPreview}
                                        />

                                        <div ref={previewSourceBottomRef} className='previewSourceBottom'>
                                            {!isCurrentViewport ? <></>
                                                :
                                                <PlayPauseBar onMoreClick={togglePreviewActions(true)} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {isCurrentViewport ? <></> :
                    <button onClick={scrollToPreviewCard}>
                        <div className='notInViewportOverlay'>
                        </div>
                    </button>
                }
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    nextPreviewsInfo: state.data.nextPreviewsInfo,
    track: state.data.track,
})

const mapActionsToProps = {
    setNextPreviewsInfo,
    setTrack,
}

PreviewCard.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(PreviewCard);