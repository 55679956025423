const ProfilePicture = (
    {
        borderSize,
        color,
        imageSrc,
        margin,
        profileClick,
        size,
    }) => {

    const onClick = (event) => {
        if (profileClick !== null) {
            profileClick(event);
        }
    }

    function reducePixels(pixelString, reduceBy) {
        // Check if the input string ends with "px" and extract the numeric part
        if (pixelString.endsWith("px")) {
          const numericValue = parseFloat(pixelString); // Extract numeric part
          if (!isNaN(numericValue)) {
            const reducedValue = numericValue - reduceBy; // Reduce the numeric value
            return `${reducedValue}px`; // Return as a string with "px"
          }
        }
        // Return the original string if it doesn't end with "px" or isn't a valid number
        return pixelString;
    }

    const innerHtmlFn = () => {
        let imagePixels = reducePixels(size, borderSize * 4);
        let border1Pixels = reducePixels(size, borderSize * 2);
        return (
            <div style={{ margin: margin }}>
            <div className="profilePicContainer" style={{ width: size, height: size }} >
                <div className="profilePicBorder2" style={{ width: size, height: size, background: color }}></div>
                <div className="profilePicBorder1" style={{ margin: `${borderSize}px`, width: border1Pixels, height: border1Pixels }}></div>
                <div style={{ zIndex: 20, position: 'absolute' }}>
                    <img title="profilePic" alt="profilePic" src={imageSrc} style={{ margin: `${borderSize * 2}px`, width: imagePixels, height: imagePixels }}>
                    </img>
                </div>
            </div>
            </div>
        )
    }

    const innerHtml = innerHtmlFn();

    return (profileClick ?
        <button onClick={onClick}>
            {innerHtml}
        </button> :
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            {innerHtml}
        </div>
    );
};

ProfilePicture.defaultProps = {

}

export default ProfilePicture;
