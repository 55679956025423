import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';

// Components.
import EntityInfoDrawer from "./EntityInfoDrawer";
import ProfilePicture from './ProfilePicture';

import { fancyTimeFormatMinutesOrSeconds } from './timeHelper';
import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";

import { getColorForNum } from '../constants/colors';
import { PREVIEW_CARD_HEIGHT, PREVIEW_CARD_MAX_WIDTH, PREVIEW_CARD_PROFILE_PIC_SIZE, PREVIEW_CARD_PROFILE_PIC_SIZE_NUM, PREVIEW_NEXT_CLIP_BUFFER } from '../constants/sizes';
import { PlayArrowRounded } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";



const ClipOrPreviewInfo = ({
    clipOrPreview,
    index,
    indexInViewport,
    playClipOrPreview,

    // Redux state.

    // Redux actions.
}) => {

    const currentViewport = useRef();

    const isCurrentViewport = useIsInViewport(currentViewport);

    const { _, width } = useWindowDimensions();
    const bottomDetailsRef = useRef();

    useEffect(() => {
        if (!isCurrentViewport) {

        } else {
            indexInViewport(index);
        }
    }, [isCurrentViewport]);

    const navigate = useNavigate();

    const navToSource = (sourceId) => () => {
        navigate(`/podcast/${sourceId}`)
    }

    const [openPersonInfo, setOpenPersonInfo] = useState(false);
    const selectedSpeakerId = useRef('');

    const togglePersonInfo = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPersonInfo(open);
    }

    const openSpeakerInfo = (speakerId) => () => {
        selectedSpeakerId.current = speakerId;
        setTimeout(() => {
            setOpenPersonInfo(true);
        }, 100);
    }

    const padding = 20;

    return (
        <div id={clipOrPreview.id}
            style={{
                // display: 'inline-block',
                opacity: 1.0,
                width: `${Math.min(width, PREVIEW_CARD_MAX_WIDTH) - PREVIEW_NEXT_CLIP_BUFFER}px`,
                background: `${getColorForNum(index)}35`,
                padding: `${padding}px`,
                borderRadius: '8px',
                height: `${PREVIEW_CARD_HEIGHT}px`,
                textAlign: 'left',
            }}>
            <EntityInfoDrawer
                open={openPersonInfo}
                onClose={togglePersonInfo(false)}
                speakerId={selectedSpeakerId.current} />

            <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
            </div>
            <div>



                <div style={{ paddingTop: `${bottomDetailsRef.current ? (PREVIEW_CARD_HEIGHT - (2 * padding) - bottomDetailsRef.current.clientHeight - PREVIEW_CARD_PROFILE_PIC_SIZE_NUM - 20) / 2 : 0}px` }}>
                    <div className="profileAndSourceImageContainer">
                        <div className="profilePicWithSourceImage" style={{ width: PREVIEW_CARD_PROFILE_PIC_SIZE }}>
                            <button onClick={openSpeakerInfo(clipOrPreview.person.id)}>
                                <ProfilePicture
                                    borderSize={2}
                                    imageSrc={clipOrPreview.person.imageURL}
                                    color={getColorForNum(index)}
                                    profileClick={null}
                                    size={PREVIEW_CARD_PROFILE_PIC_SIZE}
                                />
                            </button>
                            <div style={{ zIndex: '100', position: 'absolute', bottom: '0', right: '0' }}>
                                <button onClick={navToSource(clipOrPreview.source.id)}>
                                    <img style={{ borderRadius: '4px', verticalAlign: 'middle', width: '40px', height: '40px' }} src={clipOrPreview.source.imageURL} alt="source" />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div ref={bottomDetailsRef} style={{ position: 'absolute', bottom: '20px', width: `${Math.min(width, PREVIEW_CARD_MAX_WIDTH) - PREVIEW_NEXT_CLIP_BUFFER - (2 * padding)}px` }}>
                    <div>
                        <button onClick={openSpeakerInfo(clipOrPreview.person.id)}>
                            <div>
                                <div className='clipOrPreviewInfoSpeakerName'>
                                    {clipOrPreview.person.name}
                                </div>
                                <div className='clipOrPreviewInfoSpeakerDescription'>
                                    {clipOrPreview.person.description}
                                </div>
                            </div>
                        </button>
                    </div>
                    <div>
                        <button onClick={playClipOrPreview}>
                            <div className='clipOrPreviewInfoTitle' >
                                {clipOrPreview.title}
                            </div>
                        </button>
                    </div>
                    <div>
                        <button onClick={playClipOrPreview}>
                            <div style={{ display: 'inline-block', borderRadius: '20px', padding: '8px 12px 8px 12px', background: 'rgb(255, 255, 255)' }}>
                                <PlayArrowRounded style={{ verticalAlign: 'middle', fontSize: '26px', color: 'rgb(30, 30, 30)' }} />
                                <span style={{ fontWeight: '500', fontSize: '15px', paddingLeft: '3px', paddingRight: '8px', verticalAlign: 'middle' }}>
                                    {fancyTimeFormatMinutesOrSeconds(clipOrPreview.totalTimeSeconds)}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

ClipOrPreviewInfo.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(ClipOrPreviewInfo);