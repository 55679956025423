import axios from 'axios';

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import {
    addSpeakerSubscribeIds,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    loadLatestSubscribed,
    setGetStartedTopicsStatusTrue,
    updateForMe
} from '../redux/actions/userActions';

// Router.
import { Navigate, useNavigate } from 'react-router-dom';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.

import { ChevronLeft, Close } from '@material-ui/icons';
import GetStartedTopics from '../components/GetStartedTopics';
import PodcastFlipper from '../components/PodcastFlipper';
import { validTokenExists } from '../firebaseHelper';
import Loading from '../components/Loading';
import GetStartedSpeakers from '../components/GetStartedSpeakers';


const GetStarted = ({
    addSpeakerSubscribeIds,
    authenticated,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    loadLatestSubscribed,
    setGetStartedTopicsStatusTrue,
    updateForMe,
}) => {
    
    const [showSpeakers, setShowSpeakers] = useState(false);
    const [picksSelected, setPicksSelected] = useState(false);

    const [selectedTopics, setSelectedTopics] = useState([]);
    const [unselectedTopics, setUnselectedTopics] = useState([]);

    const [getStartedSpeakersData, setGetStartedSpeakersData] = useState(null);
    const [selectedTopicSpeakers, setSelectedTopicSpeakers] = useState([]);
    const [otherSpeakers, setOtherSpeakers] = useState([]);
    const [getStartedSpeakersError, setGetStartedSpeakersError] = useState(false);

    const navigate = useNavigate();

    const exit = () => {
        navigate('/home');
    }

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'get_started'
        });
        axios
            .get('/get_started_speakers')
            .then((res) => {
                setGetStartedSpeakersData(res.data);
            })
            .catch((err) => {
                // TODO log error.
                setGetStartedSpeakersError(true);
                console.log(err);
            });
    }, []);

    useEffect(() => {
        updateGetStartedSpeakers();
    }, [getStartedSpeakersData])

    useEffect(() => {
        updateGetStartedSpeakers();
    }, [selectedTopics])

    function shuffleAndGetFirst50(arr) {
        // Fisher-Yates shuffle algorithm
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
        }
      
        // Return the first 20 elements of the shuffled array
        return arr.slice(0, 50);
    }

    const updateGetStartedSpeakers = () => {
        if (getStartedSpeakersData === null || selectedTopics.length === 0) {
            return
        }
        // First pass add all speakers for selected topics.
        const newSelectedTopicSpeakers = [];
        const addedSpeakerIds = new Set();        
        for (let i = 0; i < selectedTopics.length; i++) {
            const topic = selectedTopics[i];
            newSelectedTopicSpeakers.push([topic, getStartedSpeakersData[topic]]);
            for (let i = 0; i < getStartedSpeakersData[topic].length; i++) {
                addedSpeakerIds.add(getStartedSpeakersData[topic][i].id)
            }
        }
        setSelectedTopicSpeakers(newSelectedTopicSpeakers);

        // Second pass add other speakers.
        const possibleOtherSpeakers = [];
        for (let topic in getStartedSpeakersData) {
            for (let i = 0; i < getStartedSpeakersData[topic].length; i++) {
                const speaker = getStartedSpeakersData[topic][i];
                if (!addedSpeakerIds.has(speaker.id)) {
                    addedSpeakerIds.add(speaker.id);
                    possibleOtherSpeakers.push(speaker);
                }
            }
        }
        const newOtherSpeakers = shuffleAndGetFirst50(possibleOtherSpeakers);
        setOtherSpeakers(newOtherSpeakers);
    }

    const topicsSelectionFinished = (selected, unselected) => {
        // TODO handle error cases and don't auto dismiss podcast flipper if request hasn't finished yet.
        selected.sort();
        setSelectedTopics(selected);
        setUnselectedTopics(unselected);
        setShowSpeakers(true);
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }

    const speakersSelectionFinished = (selectedSpeakerIds, unselectedSpeakerIds) => {
        validTokenExists()
            .then(() => {
                forMeLoadingCountIncrease();
                axios
                    .post('/save_get_started_previews', {
                        selectedTopics: selectedTopics,
                        unselectedTopics: unselectedTopics,
                        selectedSpeakerIds: selectedSpeakerIds,
                        unselectedSpeakerIds: unselectedSpeakerIds,
                    })
                    .then((res) => {
                        forMeLoadingCountDecrease();
                        if (res.data.error) {
                            // TODO display error message to user...
                            console.log(res.data.error);
                            alert("Unexpected error. Please refresh the page and try again.")
                        } else {
                            updateForMe(res.data);
                            addSpeakerSubscribeIds(selectedSpeakerIds);
                            loadLatestSubscribed();
                            setGetStartedTopicsStatusTrue();
                            onFinished();
                        }
                    })
                    .catch((err) => {
                        forMeLoadingCountDecrease();
                        // TODO display error message to user...
                        console.log(err.response.data.error);
                    });
            })
            .catch((e) => {
                forMeLoadingCountDecrease();
                // Not logged in.
            });
        setPicksSelected(true);
    }

    const onFinished = () => {
        navigate('/home');
    }

    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <div className='mainWithBottomMenu'>
                    {picksSelected ?
                        <div style={{ paddingTop: '60px' }}>
                            <PodcastFlipper loadingText='Curating podcasts for you' onFinished={onFinished} />
                        </div>
                        :
                        showSpeakers ?
                        <div style={{ padding: '0px 0px' }}>
                            <div  style={{ padding: '20px' }}>
                                <button onClick={() => { setShowSpeakers(false) }} style={{ height: '40px' }}>
                                    <ChevronLeft style={{ fontSize: '22px', verticalAlign: 'middle', color: 'rgb(124, 124, 124)' }} />
                                    <span style={{ fontSize: '15px', verticalAlign: 'middle', color: 'rgb(124, 124, 124)' }}>
                                        Back
                                    </span>
                                </button>
                                <div className='heading' style={{ marginTop: '16px', verticalAlign: 'middle' }}>
                                    Show me podcasts with...
                                </div>
                                
                            </div>

                            {getStartedSpeakersData === null ?
                                <div>
                                    {getStartedSpeakersError ? <div>Unexpected Error</div> : <div><Loading /></div>}
                                </div>
                                :
                                <div>
                                    <GetStartedSpeakers
                                        selectedTopicSpeakers={selectedTopicSpeakers}
                                        otherSpeakers={otherSpeakers}
                                        speakersSelectionFinished={speakersSelectionFinished} />
                                </div>
                            }
                        </div>
                        :
                        <div style={{ padding: '0px 0px' }}>
                            <div className='heading' style={{ padding: '20px' }}>
                                Show me podcasts on...
                            </div>

                            <GetStartedTopics
                                topicsSelectionFinished={topicsSelectionFinished} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

GetStarted.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

GetStarted.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    getStartedTopicStatus: state.user.getStartedTopicStatus,
})

const mapActionsToProps = {
    addSpeakerSubscribeIds,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    loadLatestSubscribed,
    setGetStartedTopicsStatusTrue,
    updateForMe,
}

export default connect(mapStateToProps, mapActionsToProps)(GetStarted);