import { connect } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import {
    setNextClipsInfo,
    setTrack,
} from '../redux/actions/dataActions';

import { getAnalytics, logEvent } from "firebase/analytics";
import axios from 'axios';
import { loadTrack } from '../components/audioHelper';
import { shuffle } from 'lodash';
import ClipOrPreviewInfo from '../components/ClipOrPreviewInfo';
import { PauseRounded, PlayArrowRounded } from '@material-ui/icons';
import { LABEL_TAGLINE } from '../constants/labels';
import ClipLargePreviewLoading from '../components/ClipLargePreviewLoading';
import { RAINBOW } from '../constants/colors';

const Clip = ({
    setNextClipsInfo,
    setTrack,
}) => {
    const audio = document.getElementById('audio');
    const navigate = useNavigate();
    let params = useParams();
    const analytics = getAnalytics();

    const [clipInfo, setClipInfo] = useState(null);
    const [clip, setClip] = useState(null);
    const [playClipTapped, setPlayClipTapped] = useState(false);
    const [randomIndex, setRandomIndex] = useState(getRandomIntInclusive(0, RAINBOW.length - 1));

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'clip'
        });
    }, [analytics]);

    const tapPlayClip = () => {
        if (!playClipTapped) {
            loadTrack(clipInfo.mp3URL, 0);
            setPlayClipTapped(true);
        }
    }

    useEffect(() => {
        audio.pause();
        if (!!params.trackId && !!params.messageIndex) {
            axios
                .get(`/clip_info/${params.trackId}/${params.messageIndex}`)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    setClipInfo(data);
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/missing');
                });

            axios
                .get(`/clip/${params.trackId}/${params.messageIndex}`)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    setClip(data);
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/missing');
                });
        }
    }, [params]);

    useEffect(() => {
        if (!!clip && playClipTapped) {
            navToClipCard(clip)();
        }
    }, [clip, playClipTapped]);

    const navToClipCard = (clip) => () => {
        if (clip) {
            setTrack(clip);
            const fullTrackId = clip.fullTrackId;
            const messageIndex = clip.transcript[0].messageIndex;
            const clipInfo = {
                fullTrackId: fullTrackId,
                messageIndex: messageIndex,
                summary: clip.summary,
                id: `${fullTrackId}_${messageIndex}`,
                title: clip.title,
                mp3URL: clip.mp3URL,
                totalTimeSeconds: clip.totalTimeSeconds
            }
            const addedClipIds = new Set();
            const nextClips = [];
            addedClipIds.add(clipInfo.id);
            if (clip.relatedTopicClips) {
                for (let i = 0; i < clip.relatedTopicClips.length; i++) {
                    const clips = clip.relatedTopicClips[i].clips;
                    for (let j = 0; j < clips.length; j++) {
                        const c = clips[j];
                        if (!addedClipIds.has(c.id)) {
                            nextClips.push(c);
                            addedClipIds.add(c.id);
                        }
                    }
                }
            }
            setNextClipsInfo([clipInfo].concat(shuffle(nextClips)));
            navigate('/clips');
        }
    }

    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    const playPauseClick = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play();
        }
    }

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    return (
        <div className='container'>
            <div className='center' style={{ textAlign: 'center' }}>
                <div style={{ paddingBottom: '20px'  }}>
                    <a href='/'>
                        <div>
                            <img className='appName' src='/appname2.png' />
                        </div>
                        <div className='tagline'>
                            {LABEL_TAGLINE}
                        </div>
                    </a>
                </div>
                {!!clipInfo ? 
                <div style={{ paddingBottom: '20px' }}>
                    <div className='hslider-children' style={{ margin: '10px 0px' }}>
                        <ClipOrPreviewInfo clipOrPreview={clipInfo} playClipOrPreview={tapPlayClip} index={0} indexInViewport={(x) => { }} />
                    </div>
                    <div>
                        {!playClipTapped ? <></> :
                            <div style={{ padding: '20px' }}>
                                <button onClick={playPauseClick}>
                                    <div>
                                        <div style={{ display: 'inline-block', width: '152px', padding: '10px', borderRadius: '20px', background: 'black', color: 'white' }}>
                                            {playing ?
                                                <PauseRounded style={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '20px',
                                                }} />
                                                :
                                                <PlayArrowRounded style={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '20px',
                                                }} />
                                            }
                                        </div>
                                    </div>
                                </button>
                                <div className='glow' style={{ paddingTop: '20px' }}>
                                    Loading transcript...
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                    <ClipLargePreviewLoading count={1}/>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    setNextClipsInfo,
    setTrack,
}

export default connect(mapStateToProps, mapActionsToProps)(Clip);