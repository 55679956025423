import { getAnalytics, logEvent } from "firebase/analytics";
import { shuffle } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import { setNextPreviewsInfo } from "../redux/actions/dataActions";
import { setPodcastSubscribeStatus } from '../redux/actions/userActions';
import { WebStoriesOutlined } from "@mui/icons-material";
import { loadTrack } from "./audioHelper";
import { Drawer } from "@mui/material";
import CloseFooter from "./CloseFooter";
import { useState } from "react";


const PodcastHeader = ({
    podcast,

    // Redux.
    authenticated,
    setNextPreviewsInfo,
    setPodcastSubscribeStatus,
    podcastSubscribeIds,
}) => {

    const analytics = getAnalytics();
    const navigate = useNavigate();

    const subscribeClick = () => {
        if (!authenticated) {
            return;
        }
        logEvent(analytics, !currentSubscribeStatus ? 'subscribe_true' : 'subscribe_false');
        setPodcastSubscribeStatus(podcast.id, !currentSubscribeStatus);
    }

    const isSubscribed = () => {
        const podcastSubscribeIdsSet = new Set(podcastSubscribeIds);
        return podcastSubscribeIdsSet.has(podcast.id);
    }

    const currentSubscribeStatus = isSubscribed();

    const playPreviewsClick = () => {
        const shuffledPreviews = shuffle(podcast.previews);
        playPreviews(shuffledPreviews);
    }

    const playPreviews = (previewsToPlay) => {
        loadTrack(previewsToPlay[0].mp3URL, 0)
        setNextPreviewsInfo(previewsToPlay);
        document.getElementById('audio').pause();
        navigate('/previews', { state: { closeAllModal: true } });
    }

    const [openFullSummary, setOpenFullSummary] = useState(false);

    const toggleFullSummary = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!podcast || !podcast.description) {
            return;
        }
        setOpenFullSummary(open);
    }

    function removeHTMLTags(str) {
        return str.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
    }

    return (
        <div>
            <Drawer anchor='bottom' open={openFullSummary} onClose={toggleFullSummary(false)}>
                {!podcast || !podcast.description ? <></> :
                    <div className='horizontalCenter' style={{ height: '100vh' }}>
                        <div className='hasCloseFooter'>
                            <div style={{ padding: '20px' }}>
                                <div className='heading'>
                                    Description
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {removeHTMLTags(podcast.description)}
                                </div>
                            </div>
                        </div>

                    </div>
                }
                <CloseFooter onClick={toggleFullSummary(false)} />
            </Drawer>

            <div style={{ padding: '16px 20px 10px' }}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: '100%' }}>
                                <div style={{ display: 'inline-block' }}>
                                    <div className='speakerNameLarge'>
                                        {podcast.name}
                                    </div>
                                </div>
                            </td>
                            <td style={{ minWidth: '76px', textAlign: 'right' }}>
                                <img src={podcast.imageURL} style={{ width: '60px', height: '60px', borderRadius: '8px' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {!podcast || !podcast.description ?
                <></>
                :
                <div>
                    <div style={{ padding: '10px 20px' }}>
                        <button
                            onClick={toggleFullSummary(true)}
                            style={{ textAlign: 'left' }}>
                            <div className='podcastSummary'>
                                {removeHTMLTags(podcast.description)}
                            </div>
                        </button>
                    </div>
                </div>
            }
            <table style={{ padding: '4px 16px 0px', width: '100%' }}>
                <tbody>
                    <tr>
                        {!authenticated ? <></> :
                            <td style={{ width: '50%', padding: '0px 4px' }}>
                                <button className='entityInfoTopButton' onClick={subscribeClick}>
                                    <img alt='subscribe' src={currentSubscribeStatus ? '/subscribe_fill.png' : '/subscribe_outline.png'} style={{ width: '24px', verticalAlign: 'middle', marginRight: '12px' }} />
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {currentSubscribeStatus ? 'Subscribed' : 'Subscribe'}
                                    </span>
                                </button>
                            </td>
                        }
                        {!podcast.previews ?
                            <></> :
                            <td style={{ width: '50%', padding: '0px 4px' }}>
                                <button className='entityInfoTopButton' onClick={playPreviewsClick}>
                                    <WebStoriesOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle', margin: '0px 3px 0px 12px' }}>
                                        Previews
                                    </span>
                                </button>
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    podcastSubscribeIds: state.user.podcastSubscribeIds,
})

const mapActionsToProps = {
    setNextPreviewsInfo,
    setPodcastSubscribeStatus,
}

export default connect(mapStateToProps, mapActionsToProps)(PodcastHeader);
