import axios from "axios";
import { validTokenExists } from "../firebaseHelper";

export const eventClipView = (clipId, seconds) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/clip_view', {
                    clipId: clipId,
                    seconds: seconds,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};


export const eventPreviewView = (previewId, seconds) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/preview_view', {
                    previewId: previewId,
                    seconds: seconds,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};


export const eventLog = (type, details) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/log', {
                    type: type,
                    details: details,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};


export const report = (type, details) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/report', {
                    type: type,
                    details: details,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};


export const eventClipShared = (clipId) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/clip_shared', {
                    clipId: clipId,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};


export const eventPreviewShared = (previewId) => {
    validTokenExists()
        .then(() => {
            axios
                .post('/preview_shared', {
                    previewId: previewId,
                })
                .then((res) => {
                    if (res.data.error) {
                        // TODO display error message to user...
                        console.log(res.data.error);
                    }
                })
                .catch((err) => {
                    // TODO display error message to user...
                    console.log(err.response.data.error);
                });
        })
        .catch((e) => {
            // Not logged in.
        });
};