import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react'
import { getAnalytics } from "firebase/analytics";

import { Drawer } from '@mui/material';

// Components.
import ProfilePicture from './ProfilePicture';

import useWindowDimensions from './windowHelper';
import { RESPONSIVE_SMALL_HEIGHT } from '../constants/sizes';
import { useLongPress } from 'use-long-press';


const Message = ({
    authenticated,
    curTime,
    message,
    isCurrent,
    isLastMessage,
    borderColor,
    openSpeakerInfo,
    playing,
    scrollToCurrentMessage,
    seekFn,
    speaker,
    showSpeakerDetails,
}) => {

    const messageButtonRef = useRef();
    const messageText = useRef(null);
    const { height } = useWindowDimensions();

    useEffect(() => {
        const fullMessageText = [];
        for (let i = 0; i < message.message.length; i++) {
            fullMessageText.push(message.message[i].text);
        }
        messageText.current = fullMessageText.join('');
    }, []);

    const onMessageClick = () => {
        seekFn(message.timeSeconds + 0.01)()
    }

    const onProfileClick = () => {
        if (speaker.id !== 'unknown') {
            openSpeakerInfo(speaker.id)();
        }
    }

    const onSpeakerLongPress = () => {

    }

    const bindSpeakerLongPress = useLongPress(onSpeakerLongPress, {
        onCancel: event => {
            if (event.type === 'pointerup') {
                onProfileClick();
            }
        },
        filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
        threshold: 300, // In milliseconds
        captureEvent: true, // Event won't get cleared after React finish processing it
        cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
        cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
        detect: 'pointer', // Default option
    });

    const onMessageLongPress = () => {
        
    }

    const bindMessageLongPress = useLongPress(onMessageLongPress, {
        onCancel: event => {
            if (event.type === 'pointerup') {
                onMessageClick();
            }
        },
        filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
        threshold: 300, // In milliseconds
        captureEvent: true, // Event won't get cleared after React finish processing it
        cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
        cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
        detect: 'pointer', // Default option
    });

    const messageClassName = height > RESPONSIVE_SMALL_HEIGHT ? 'messageLarge' : 'message'

    return (
        <div className='messageContainer'>
            <div>
                <div style={{
                    // borderColor: borderColor,
                    opacity: isCurrent || !playing || !scrollToCurrentMessage ? 1.0 : 0.3,

                    // filter: isCurrent || !playing ? '' : 'blur(0.2px)',
                }}>
                    {showSpeakerDetails && speaker ?
                        <button {...bindSpeakerLongPress()} style={{ width: '100%' }}>
                            <div style={{ padding: '16px' }}>
                                <table style={{
                                    width: '100%',
                                    borderSpacing: 0,
                                }}>
                                    <tbody>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            <td style={{ width: '60px' }}>
                                                <ProfilePicture
                                                    borderSize={2}
                                                    imageSrc={speaker.imageURL}
                                                    color={borderColor}
                                                    size='48px'
                                                    margin='0px 12px 0px 0px' />
                                            </td>
                                            <td>
                                                <div className='speakerNameSmall'>
                                                    {speaker.name}
                                                </div>
                                                <div className='speakerDescription'>
                                                    {speaker.id === 'unknown' && authenticated ?
                                                        <span>

                                                        </span>
                                                        :
                                                        speaker.description}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </button>
                        :
                        <></>
                    }
                    <div style={{ padding: '0px 16px 16px' }}>
                        <button {...bindMessageLongPress()} ref={messageButtonRef} style={{ width: '100%' }}>
                            <div className='preventSelect' style={{
                                textAlign: 'left',
                            }}>
                                <div>
                                    {isCurrent ?
                                        message.message.map((word, wordIndex) => (
                                            <span key={wordIndex} className={messageClassName}
                                                style={{
                                                    opacity: !!curTime && curTime + 0.4 < word.start ? 0.5 : 1.0
                                                }}
                                            >
                                                {word.text.replace(' [redundant phrase removal],', '')}
                                            </span>

                                        ))
                                        :
                                        <span className={messageClassName}>
                                            {!messageText.current ? <></> :
                                                messageText.current.replaceAll(' [redundant phrase removal],', '')}
                                        </span>
                                    }
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Message.defaultProps = {

}

Message.propTypes = {
    openSpeakerInfo: PropTypes.func.isRequired,
}

export default Message;
